/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-sort-props */
/* eslint-disable no-console */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';

// Externals
import { useSelector, useDispatch } from 'react-redux';
import {
  Chip,
  Grid,
} from '@material-ui/core';
import PerfectScrollbar from 'react-perfect-scrollbar';

// Material helpers
import {
  makeStyles,
  TableRow,
  TableCell,
  TableHead,
  Paper,
  Table,
  TableBody,
  CircularProgress
} from '@material-ui/core';

// Component styles
import styles from './styles';
import { useIntl } from 'react-intl';
import theme from 'theme';
import moment from 'moment';
import { getAngle } from 'js/actions/chargeDashboardActions';
const useStyles = makeStyles(styles);

export default function ChargeTable(props) {
  const intl = useIntl();
  const classes = useStyles();
  const dispatch = useDispatch();
  const { ids, concentratorId, charge, fp } = props;
  const angle = useSelector(
    state => state.chargeDashboard.angle
  );

  const getCurrentMonth = () => {
    let month = new Date().getMonth() + 1;
    let year = new Date().getFullYear();
    if (month < 10) {
      return '0' + month;
    } else {
      return `${year}${month}`;
    }
  };

  useEffect(()=>{
    dispatch(getAngle(fp, concentratorId, charge_id, getCurrentMonth()))
  },[])

  const charge_id = charge ? charge.id : 0;

  const processTableData = (data) => {
    // `res` contendrá la estructura por día y hora
    const res = {};
  
    Object.keys(data).forEach(date => {
      const hours = Object.keys(data[date]).filter(key => !isNaN(key));
      // Procesar las horas y sus datos
      hours.forEach(hour => {
        if (!res[hour]) res[hour] = [];
        res[hour].push({
          date,
          hour,
          ...data[date][hour]
        });
      });
    });
  
    return res;
  };

  
  const res = processTableData(angle);

  const [days, setDays] = useState([]);
  const localeNav = useSelector(state => state.prefs.locale);

  useEffect(() => {
    const daysInMonth = moment().daysInMonth();
    const currentMonth = moment().month();
    const currentYear = moment().year();
    const daysArray = [];
    moment.locale(localeNav);
    for (let i = 1; i <= daysInMonth; i++) {
      const date = moment({ year: currentYear, month: currentMonth, day: i });
      daysArray.push({
        day: i,
        weekday: date.format('ddd'),
        date: date.format('YYYYMMDD')
      });
    }

    setDays(daysArray);
  }, [localeNav]);

  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <Grid
          container
          lg={12}
          md={12}
          xl={12}
          xs={12}
          style={{
            width: '100%',
            justifyContent: 'space-between',
            marginBottom: theme.spacing.unit,
            alignItems: 'center'
          }}
        >
          <Chip
            label="Reporte Diario de Potencia Reactiva"
            className={classes.title}
          />
          <Grid
            item
            spacing={2}
            className={classes.topBar}
          >
            <Chip
              label="Normal"
              style={{
                backgroundColor: '#45B880',
                fontSize: '.95rem',
                fontWeight: 500
              }}
            />
            <Chip
              label="Precaución"
              style={{
                backgroundColor: '#FEC12C',
                fontSize: '.95rem',
                fontWeight: 500
              }}
            />
            <Chip
              label="Excedido"
              style={{
                backgroundColor: '#ED685E',
                fontSize: '.95rem',
                fontWeight: 500
              }}
            />
          </Grid>
        </Grid>
        <PerfectScrollbar>
          <Table
            className={classes.table}
            size="small"
            style={{ width: '100%' }}
          >
            <TableHead>
              {angle != undefined || angle != null ? (
                <TableRow>
                  <TableCell className={classes.positionLeft}>
                    {intl.formatMessage({
                      id: 'app.charge.board.dailyRepDemand.hourLab'
                    })}
                  </TableCell>
                  {days.map((day, index) => (
                    <TableCell key={index}>
                      {day.day}
                      <br />
                      {day.weekday.charAt(0).toUpperCase() +
                        day.weekday.slice(1, 3)}
                    </TableCell>
                  ))}
                  {/* {Object.values(generalReport).map((columns,index) => (
                  <TableCell >
                    {index + 1}
                  </TableCell>
                ))} */}
                </TableRow>
              ) : null}
            </TableHead>

            {angle ? (
              <TableBody style={{ padding: '0 !important' }}>
                {Object.keys(res)
                  .sort((a, b) => a - b)
                  .map(hour => (
                    <TableRow
                      style={{ height: '0px' }}
                      key={hour}
                    >
                      <TableCell
                        component="th"
                        scope="row"
                        className={classes.positionLeft}
                      >
                        {hour}:00
                      </TableCell>
                      {days.map(day => {
                        const entry = res[hour].find(e => e.date === day.date);
                        return (
                          <TableCell
                            key={day.date}
                            component="th"
                            scope="row"
                            style={{
                              backgroundColor: entry !== undefined && entry.color ? entry.color : '#e1e1e1',
                              fontWeight: entry !== undefined && entry.exceeds_50_percent ? 'bold' : 'normal',
                              color: '#000',
                              border: '1px solid #7e7e7e'
                            }}
                          >
                            {entry ? entry.kvarh : ''}
                          </TableCell>
                        );
                      })}
                    </TableRow>
                  ))}
              </TableBody>
            ) : (
              <CircularProgress />
            )}
          </Table>
        </PerfectScrollbar>
      </Paper>
    </div>
  );
}

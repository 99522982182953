/* eslint-disable react/display-name */

/* eslint-disable react/no-multi-comp */
/* eslint-disable react/jsx-no-undef */
import { es } from 'date-fns/locale';
import { getGeneralConsume, getGeneralReport } from 'js/actions/chargeDashboardActions';
import React, { forwardRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { Button } from '@material-ui/core';

export const DatePick = (props) => {
  const dispatch = useDispatch();
  const { ids, setMonth, setYearChange, setChangeMonthYear, changeMonthYear} = props;
  const localeNav = useSelector(state => state.prefs.locale);
  const width = window.innerWidth;

  const handleHistory = (date) => {
    setChangeMonthYear(date);

    const month = date.getMonth() + 1 > 9 ? date.getMonth() + 1 : `0${date.getMonth() + 1}`;
    const year = date.getFullYear();

    setMonth(month);
    setYearChange(year);
    
    dispatch(getGeneralReport(ids, year, month));
    dispatch(getGeneralConsume(ids.chargeId, year, month))
  }

  const renderMonthContent = (month, shortMonth, longMonth, day) => {
    const fullYear = new Date(day).getFullYear();
    const tooltipText = `Tooltip for month: ${longMonth} ${fullYear}`;

    return <span title={tooltipText}>{shortMonth}</span>;
  };

  const ExampleCustomInput = forwardRef(
    ({ value, onClick, className }, ref) => (
      <Button
        className={className}
        color="secondary"
        onClick={onClick}
        ref={ref}
        size="large"
        variant="contained"
      >
        {value}
      </Button>
    ),
  );

  return (
    <>
      <DatePicker
        customInput={<ExampleCustomInput className="example-custom-input" />}
        dateFormat={width > 768 ? 'MMMM yyyy' : 'MMM yyyy'}
        locale={localeNav === 'es-MX' ? es : 'en'}
        maxDate={new Date()}
        onChange={handleHistory}
        renderMonthContent={renderMonthContent}
        selected={changeMonthYear}
        showMonthYearPicker
      />
    </>
  )
}
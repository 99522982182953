/* eslint-disable react/no-multi-comp */
/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-closing-bracket-location */
/* eslint-disable react/jsx-sort-props */
/* eslint-disable no-console */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';

// Externals
import { useSelector, useDispatch } from 'react-redux';
import PerfectScrollbar from 'react-perfect-scrollbar';
import 'react-perfect-scrollbar/dist/css/styles.css';
import {
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormGroup,
  InputLabel,
  Link,
  MenuItem,
  Select,
  Typography
} from '@material-ui/core';
import { withStyles, Chip } from '@material-ui/core';
//import Scrollbar from 'react-smooth-scrollbar';
import axios from 'axios';
import getToken from 'helpers/auth';
import 'react-datepicker/dist/react-datepicker.css';
import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker
} from '@material-ui/pickers';

import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';

import { Button } from '@material-ui/core';

import {
  makeStyles,
  TableRow,
  TableCell,
  TableHead,
  CircularProgress,
  Paper,
  Table,
  TableBody,
  Grid
} from '@material-ui/core';

// Component styles
import styles from './styles';
import { useIntl } from 'react-intl';
import { ar, da } from 'date-fns/locale';
import theme from 'theme';
import moment from 'moment';
import MaterialTable from 'material-table';
import Swal from 'sweetalert2';
const useStyles = makeStyles(styles);

export default function ChargeTable(props) {
  const intl = useIntl();
  const classes = useStyles();
  const dispatch = useDispatch();
  const { ids, heatMap, setHeatMap, valueInfo, setValueInfo,updateMonth,updateYear } = props;

  const [open, setOpen] = React.useState(false);
  const [openVoltage, setOpenVoltage] = React.useState(false);
  const [dataConsume, setDataConsume] = React.useState([]);
  const [dataVoltage, setDataVoltage] = React.useState([]);
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [showStartDatePop, setShowStartDatePop] = useState(false);
  const [showEndDatePop, setShowEndDatePop] = useState(false);
  const [errorMessage, setErrorMessage] = useState();
  const [chargeVoltageId, setChargeVoltageId] = useState(null);
  const [stats, setStats] = useState([]);
  // const [timeLabel, setTimeLabel] = useState({min: true, hour: false});

  const concentrator_id = useSelector(
    state => state.chargeDashboard.generalConsumes.concentrator_id
  );
  const charge = useSelector(
    state => state.chargeDashboard.generalConsumes.charge_info
  );
  const charge_id = charge ? charge.id : 0;

  const calculateStats = data => {
    if (data.length === 0) return { min: 0, max: 0, avg: 0 };
    const values = data.map(item => item[1]);
    const min = Math.min(...values).toFixed(2);
    const max = Math.max(...values).toFixed(2);
    const avg = (
      values.reduce((sum, value) => sum + value, 0) / values.length
    ).toFixed(2);
    return { min, max, avg };
  };

  useEffect(() => {
    dispatch(voltageValidate());
  }, []);

  // useEffect(()=>{
  //   filter5MinRecords( startDate ,  endDate);
  //   filter5MinRecordsVoltage( startDate ,  endDate);
  // }, [timeLabel])

  const handleClickOpen = () => {
    setOpen(true);
    filter5MinRecords(startDate, endDate);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleClickOpenVoltage = () => {
    setOpenVoltage(true);
    filter5MinRecordsVoltage(startDate, endDate);
  };

  const handleCloseVoltage = () => {
    setOpenVoltage(false);
  };

  const handleHM = event => {
    const { name, checked } = event.target;
    if (name === 'fee' && checked) {
      setHeatMap({
        fee: true,
        hm: false
      });
    } else if (name === 'hm' && checked) {
      setHeatMap({
        fee: false,
        hm: true
      });
    }
  };

  const getColorFont = obj => {
    if (obj.max_kwh == 1) {
      return '#ad1f1f';
    } else if (obj.min_kwh == 1) {
      return '#3ba400';
    } else {
      return '#12161B';
    }
  };

  const options = {
    turboThreshold: 0,
    time: {
      useUTC: false,
      timezone: 'America/Mexico_City'
    },
    credits: {
      enabled: false
    },
    title: {
      text: intl.formatMessage({ id: 'app.deepview.consumption.chart.title' })
    },
    xAxis: {
      type: 'datetime',
      crosshair: true,
      dateTimeLabelFormats: {
        millisecond: '%H:%M:%S.%L',
        second: '%H:%M:%S',
        minute: '%H:%M',
        hour: '%H:%M',
        day: '%a %e. %b',
        week: '%e. %b',
        month: '%b \'%y',
        year: '%Y'
      }
    },
    yAxis: [
      {
        title: {
          text: 'kWh'
        },
        labels: {
          format: '{value}kWh'
        }
      }
    ],
    rangeSelector: {
      enabled: true,
      selected: 4
    },
    tooltip: {
      positioner: function(labelWidth, labelHeight, point) {
        const chart = this.chart;
        // const position = point.plotY + chart.plotTop - 100; // Ajusta según tus necesidades
        // return { x: point.plotX + chart.plotLeft, y: position };
        let x = point.plotX + chart.plotLeft;
        let y = point.plotY + chart.plotTop - 100;

        if (x + labelWidth > chart.chartWidth) {
          x = chart.chartWidth - labelWidth - 10;
        }
        if (y + labelHeight > chart.chartHeight) {
          y = chart.chartHeight - labelHeight - 10;
        }
        return { x: x, y: y };
      },

      formatter: function() {
        const date = new Date(this.x);
        const options = {
          year: 'numeric',
          month: 'short',
          day: '2-digit',
          hour: 'numeric',
          minute: 'numeric',
          weekday: 'long'
        };

        const formattedDate = date
          .toLocaleString('en-US', options)
          .replace(',', ''); // replace the comma with an empty string

        return (
          '<b>' +
          this.series.name +
          '</b><br/>' +
          formattedDate +
          //new Date(this.x).toString()
          '<br/>' +
          Highcharts.numberFormat(this.y, 2) +
          ' kWh'
        );
      }
    },
    series: [
      {
        name: intl.formatMessage({
          id: 'app.deepview.consumption.chart.demandSerie.label'
        }),
        turboThreshold: 0,
        data: dataConsume /* dataConsume.map((point) => ({
        x: point[0],
        y: point[1],
        color: point[1] > 0.5 ? "red" : "green",
      }))  */,
        id: 'dataseries',

        tooltip: {
          valueDecimals: 4
        }
      }
    ],
    legend: {
      itemStyle: {
        fontSize: '16px',
        fontWeight: 'bold'
      }
    }
  };

  const optionsVoltage = {
    turboThreshold: 0,
    time: {
      useUTC: false,
      timezone: 'America/Mexico_City'
    },
    credits: {
      enabled: false
    },
    title: {
      text: intl.formatMessage({ id: 'app.deepview.voltage.chart.title' })
    },
    xAxis: {
      type: 'datetime',
      crosshair: true,
      dateTimeLabelFormats: {
        millisecond: '%H:%M:%S.%L',
        second: '%H:%M:%S',
        minute: '%H:%M',
        hour: '%H:%M',
        day: '%a %e. %b',
        week: '%e. %b',
        month: '%b \'%y',
        year: '%Y'
      }
    },
    yAxis: [
      {
        title: {
          text: 'kWh'
        },
        labels: {
          format: '{value}kWh'
        }
      }
    ],
    rangeSelector: {
      enabled: true,
      selected: 4
    },
    tooltip: {
      positioner: function(labelWidth, labelHeight, point) {
        const chart = this.chart;
        const position = point.plotY + chart.plotTop - 100; // Ajusta según tus necesidades
        return { x: point.plotX + chart.plotLeft, y: position };
      },

      formatter: function() {
        const date = new Date(this.x);
        const options = {
          year: 'numeric',
          month: 'short',
          day: '2-digit',
          hour: 'numeric',
          minute: 'numeric',
          weekday: 'long'
        };

        const formattedDate = date
          .toLocaleString(intl.formatMessage({ id: 'app.locale' }), options)
          .replace(',', ''); // replace the comma with an empty string

        return (
          '<b>' +
          this.series.name +
          '</b><br/>' +
          formattedDate +
          //new Date(this.x).toString()
          '<br/>' +
          Highcharts.numberFormat(this.y, 2) +
          ' '
        );
      }
    },
    series: [
      {
        name: intl.formatMessage({
          id: 'app.deepview.voltage.chart.demandSerie.label'
        }),
        turboThreshold: 0,
        data: dataVoltage /* dataConsume.map((point) => ({
        x: point[0],
        y: point[1],
        color: point[1] > 0.5 ? "red" : "green",
      }))  */,
        id: 'dataseries',

        tooltip: {
          valueDecimals: 4
        }
      }
    ],
    legend: {
      itemStyle: {
        fontSize: '16px',
        fontWeight: 'bold'
      }
    }
  };

  const generalReport = useSelector(
    state => state.chargeDashboard.generalReport.report
  );

  const totalPerHour = useSelector(
    state => state.chargeDashboard.generalReport.total_per_hour
  );

  const totalPerDay = useSelector(
    state => state.chargeDashboard.generalReport.total_per_day
  );

  const totalTotalsPerDay = useSelector(
    state => state.chargeDashboard.generalReport.total_totals_per_day
  );

  const totalsDaysOfWeek = useSelector(
    state => state.chargeDashboard.generalReport.totals_days_of_week
  );

  const totalsPerHorary = useSelector(
    state => state.chargeDashboard.generalReport.totals_per_horary
  );

  const averagePerRange = useSelector(
    state => state.chargeDashboard.generalReport.average_per_range
  );

  const year = new Date().getFullYear();
  const getCurrentMonth = () => {
    let month = new Date().getMonth() + 1;
    if (month < 10) {
      return '0' + month;
    } else {
      return month;
    }
  };
  const data = [];
  let obj;

  if (generalReport != undefined || generalReport != null) {
    Object.values(generalReport).forEach(element => {
      element.forEach(element2 => {
        let obj = {
          hora: parseInt(element2.Hora),
          medicion: element2.Kwh,
          color: heatMap.fee
            ? element2.color
            : valueInfo.money
              ? element2.color_cost
              : element2.color_hm_kwh,
          alerta_consumo: element2.alerta_consumo,
          max_day: element2.max_day,
          max_month: element2.max_month,
          min_day: element2.min_day,
          min_month: element2.min_month,
          font_color: getColorFont(element2),
          border: element2.alerta_consumo == 1 ||
          element2.max_kwh == 1 ||
          element2.min_kwh == 1
            ? '3px solid black'
            : '1px solid rgb(126, 126, 126)',
          font_size: element2.alerta_consumo == 1 ||
          element2.max_kwh == 1 ||
          element2.min_kwh == 1
            ? '16px'
            : '12px',
          font_weight:
            element2.alerta_consumo == 1 ||
            element2.max_kwh == 1 ||
            element2.min_kwh == 1
              ? 'bold'
              : 'normal',
          cost_kwh: parseFloat(element2.cost_kwh).toFixed(2)
        };
        data.push(obj);
      });
    });
  }

  const calculateHourlyStatistics = () => {
    const hourlyData = Array.from({ length: 24 }, () => []);

    data.forEach(element => {
      const hour = element.hora;
      const cost = parseFloat(element.cost_kwh);
      if (cost !== 0) {
        hourlyData[hour].push(cost);
      }
    });

    const minCosts = hourlyData.map(hourCosts =>
      hourCosts.length ? Math.min(...hourCosts) : null
    );
    const maxCosts = hourlyData.map(hourCosts =>
      hourCosts.length ? Math.max(...hourCosts) : null
    );
    const avgCosts = hourlyData.map(hourCosts =>
      hourCosts.length
        ? (
          hourCosts.reduce((acc, curr) => acc + curr, 0) / hourCosts.length
        ).toFixed(2)
        : null
    );

    return {
      min: minCosts,
      max: maxCosts,
      average: avgCosts
    };
  };

  const statistics = calculateHourlyStatistics();

  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD'
  });

  if (totalPerHour !== undefined || generalReport != null) {
    Object.values(totalPerHour).forEach((hourData, index) => {
      let obj = data.find(d => d.hora === index);
      if (obj) {
        obj.min = valueInfo.kwh ? hourData.min : `$${statistics.min[index]}`;
        obj.max = valueInfo.kwh ? hourData.max : `$${statistics.max[index]}`;
        obj.prom = valueInfo.kwh
          ? hourData.prom
          : formatter.format(hourData.prom_cost_kwh);
        obj.colorProm =
          valueInfo.money && heatMap.hm
            ? hourData.color_cost
            : valueInfo.kwh && heatMap.hm
              ? hourData.color_hm
              : 'white';
      }
    });
  }

  const res = data.reduce((acc, curr) => {
    if (!acc[curr.hora]) acc[curr.hora] = []; // If this type wasn't previously stored
    acc[curr.hora].push(curr);
    return acc;
  }, {});

  const thumbHorizontal = ({ style, ...props }) => {
    const finalStyle = {
      cursor: 'pointer',
      backgroundColor: 'rgba(255,0,0,.6)'
    };
    return <div
      style={finalStyle}
      {...props} />;
  };

  const handleSelectStartDate = iniDate => {
    setStartDate(iniDate);
    setShowStartDatePop(false);
    if (openVoltage) {
      setDataVoltage([]);
      setErrorMessage(null);
      filter5MinRecordsVoltage(iniDate, endDate);
    } else {
      setDataConsume([]);
      setErrorMessage(null);
      filter5MinRecords(iniDate, endDate);
    }
  };

  const handleSelectEndDate = finDate => {
    if (openVoltage) {
      setEndDate(finDate);
      setShowEndDatePop(false);
      filter5MinRecordsVoltage(startDate, finDate);
    } else {
      setEndDate(finDate);
      setShowEndDatePop(false);
      filter5MinRecords(startDate, finDate);
    }
  };

  const set5MinDates = (data, isVoltage) => {
    let idx = 0;
    let arr_days = [];
    let arrData = [];
    const hourlyData = {};
    for (idx = 0; idx < data.length; idx++) {
      const dateStr = data[idx].date.toString();
      const year = parseInt(dateStr.substring(0, 4));
      const month = parseInt(dateStr.substring(4, 6)) - 1;
      const day = parseInt(dateStr.substring(6, 8));
      const hour = parseInt(dateStr.substring(8));
      const date = new Date(year, month, day, hour);
      const dateKey = date.getTime();

      if (!hourlyData[dateKey]) {
        hourlyData[dateKey] = {
          date: date,
          total: 0,
          count: 0
        };
      }

      arr_days = data[idx].data;
      for (let jj = 0; jj < arr_days.length; jj++) {
        const dayData = arr_days[jj];
        // hourlyData[dateKey].maxValue = Math.max(hourlyData[dateKey].maxValue, dayData.value);
        hourlyData[dateKey].total += dayData.value;
        hourlyData[dateKey].count++;
      }
    }

    for (const dateKey in hourlyData) {
      const hourlyDataPoint = hourlyData[dateKey];
      const sumValue = hourlyDataPoint.total;
      arrData.push([hourlyDataPoint.date.getTime(), sumValue]);
      // arrData.push([hourlyDataPoint.date.getTime(), hourlyDataPoint.maxValue]);
    }
    // if(timeLabel.hour) {
    //   const hourlyData = {};
    //   for (idx = 0; idx < data.length; idx++) {
    //     const dateStr = data[idx].date.toString();
    //     const year = parseInt(dateStr.substring(0, 4));
    //     const month = parseInt(dateStr.substring(4, 6)) - 1;
    //     const day = parseInt(dateStr.substring(6, 8));
    //     const hour = parseInt(dateStr.substring(8));
    //     const date = new Date(year, month, day, hour);
    //     const dateKey = date.getTime();

    //     if (!hourlyData[dateKey]) {
    //       hourlyData[dateKey] = {
    //         date: date,
    //         maxValue: -Infinity
    //         // total: 0,
    //         // count: 0,
    //       };
    //     }

    //     arr_days = data[idx].data;
    //     for (let jj = 0; jj < arr_days.length; jj++) {
    //       const dayData = arr_days[jj];
    //       hourlyData[dateKey].maxValue = Math.max(hourlyData[dateKey].maxValue, dayData.value);
    //       // hourlyData[dateKey].total += dayData.value;
    //       // hourlyData[dateKey].count++;
    //     }
    //   }

    //   for (const dateKey in hourlyData) {
    //     const hourlyDataPoint = hourlyData[dateKey];
    //     // const sumValue = hourlyDataPoint.total;
    //     // arrData.push([hourlyDataPoint.date.getTime(), sumValue]);
    //     arrData.push([hourlyDataPoint.date.getTime(), hourlyDataPoint.maxValue]);
    //   }
    // } else {
    //   for(  idx=0; idx< data.length ; idx++  ){
    //     let jj=0;
    //     let arr_days=data[idx].data;
    //     let str = data[idx].date.toString(); // yyyy-mm-dd-hh format

    //     let year = parseInt(str.substring(0, 4));
    //     let month = parseInt(str.substring(4, 6))-1;
    //     let day = parseInt(str.substring(6, 8));
    //     let hour = parseInt(str.substring(8));

    //     for( jj=0; jj<arr_days.length; jj++  ){
    //       let dayData=arr_days[jj];
    //       let minute= dayData.min;
    //       let date = new Date(year, month, day, hour, minute);
    //       let milisecs= date.getTime();
    //       let valData=[  milisecs , dayData.value ]
    //       arrData.push( valData )

    //     }
    //   }

    if (isVoltage) {
      setDataVoltage(arrData);
      setStats(calculateStats(arrData));
    } else {
      setDataConsume(arrData);
      setStats(calculateStats(arrData));
    }
  };

  const filter5MinRecords = (fechaIni, fechaFin) => {
    let dayDifs = getDateDiffInDays(fechaIni, fechaFin);
    if (dayDifs > 31) {
      setErrorMessage(
        '[' +
          dayDifs +
          ' ' +
          intl.formatMessage({
            id: 'app.deepview.consumption.datesRangeError.rangeTooLong'
          })
      );
      return;
    } else {
      if (dayDifs < 0) {
        setErrorMessage(
          intl.formatMessage({
            id: 'app.deepview.consumption.datesRangeError.wrongIniEnd'
          })
        );
        return;
      }

      setErrorMessage(null);
    }

    let txtIniDate = getDateAsString(fechaIni);
    let txtEndDate = getDateAsString(fechaFin);
    txtIniDate = txtIniDate + '00';
    txtEndDate = txtEndDate + '23';
    dispatch(get5MinRecords(txtIniDate, txtEndDate));
  };

  const getDateDiffInDays = (startDate, endDate) => {
    const diffTime = endDate - startDate;
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    return diffDays + 1;
  };

  const getDateAsString = date => {
    let year = date.getFullYear();
    let month = date.getMonth() + 1;
    let day = date.getDate();

    let strMonth = month.toString();
    if (month < 10) {
      strMonth = '0' + strMonth;
    }
    let strDay = day.toString();
    if (day < 10) {
      strDay = '0' + strDay;
    }

    let strDate = year + strMonth + strDay;
    return strDate;
  };

  const get5MinRecords = (startDate, endDate) => {
    return async dispatch => {
      axios
        .post(
          'https://api.metimur.mx/api/charge/5minutal',   
          {
            concentrator_id: concentrator_id,
            charge_id: charge_id,
            start_date: /*'2023050100'*/ startDate,
            end_date: /* '2023051023'*/ endDate
          },
          {
            headers: { Authorization: 'Bearer ' + getToken() }
          }
        )
        .then(response => {
          set5MinDates(response.data.data, false);
        })
        .catch(function(error) {
          console.log('error:' + error);
        });
    };
  };

  const voltageValidate = () => {
    return async dispatch => {
      axios
        .post(
          'https://api.metimur.mx/api/charge/voltagevalidation',   
          {
            charge_id: charge_id
          },
          {
            headers: { Authorization: 'Bearer ' + getToken() }
          }
        )
        .then(response => {
          //set5MinDates(response.data.data);
          configVoltageChage(response.data.data);
        })
        .catch(function(error) {
          console.log('error:' + error);
        });
    };
  };
  const configVoltageChage = data => {
    if (!data) {
      setChargeVoltageId(null);
      return;
    }
    if (data.has_voltage == 1) {
      setChargeVoltageId(data.charge_voltage);
    } else {
      setChargeVoltageId(null);
    }
  };
  const filter5MinRecordsVoltage = (fechaIni, fechaFin) => {
    let dayDifs = getDateDiffInDays(fechaIni, fechaFin);
    if (dayDifs > 31) {
      setErrorMessage(
        '[' +
          dayDifs +
          ' ' +
          intl.formatMessage({
            id: 'app.deepview.consumption.datesRangeError.rangeTooLong'
          })
      );
      return;
    } else {
      if (dayDifs < 0) {
        setErrorMessage(
          intl.formatMessage({
            id: 'app.deepview.consumption.datesRangeError.wrongIniEnd'
          })
        );
        return;
      }
      setErrorMessage(null);
    }
    let txtIniDate = getDateAsString(fechaIni);
    let txtEndDate = getDateAsString(fechaFin);
    txtIniDate = txtIniDate + '00';
    txtEndDate = txtEndDate + '23';
    dispatch(get5MinRecordsVoltage(txtIniDate, txtEndDate));
  };

  const get5MinRecordsVoltage = (startDate, endDate) => {
    return async dispatch => {
      axios
        .post(
          'https://api.metimur.mx/api/charge/5minutalvoltage',   
          {
            concentrator_id: concentrator_id,
            charge_id: chargeVoltageId,
            start_date: /*'2023050100'*/ startDate,
            end_date: /* '2023051023'*/ endDate
          },
          {
            headers: { Authorization: 'Bearer ' + getToken() }
          }
        )
        .then(response => {
          set5MinDates(response.data.data, true);
        })
        .catch(function(error) {
          console.log('error:' + error);
        });
    };
  };

  // const handleTime = (event) => {
  //   const { name, checked } = event.target;
  //   if (name === 'min' && checked) {
  //     setTimeLabel({
  //       min: true,
  //       hour: false,
  //     });
  //   } else if (name === 'hour' && checked) {
  //     setTimeLabel({
  //       min: false,
  //       hour: true,
  //     });
  //   }
  // }

  const handleValue = event => {
    const { name, checked } = event.target;
    if (name === 'kwh' && checked) {
      setValueInfo({
        kwh: true,
        money: false
      });
    } else if (name === 'money' && checked) {
      Swal.fire({
        icon: 'warning',
        title: intl.formatMessage({ id: 'app.barmenu.attention' }),
        text: intl.formatMessage({ id: 'app.barmenu.message' })
      });
      setValueInfo({
        kwh: false,
        money: true
      });
    }
  };

  Highcharts.setOptions({
    lang: {
      months: [
        intl.formatMessage({ id: 'app.months.jan' }),
        intl.formatMessage({ id: 'app.months.feb' }),
        intl.formatMessage({ id: 'app.months.mar' }),
        intl.formatMessage({ id: 'app.months.apr' }),
        intl.formatMessage({ id: 'app.months.may' }),
        intl.formatMessage({ id: 'app.months.jun' }),
        intl.formatMessage({ id: 'app.months.jul' }),
        intl.formatMessage({ id: 'app.months.ago' }),
        intl.formatMessage({ id: 'app.months.sep' }),
        intl.formatMessage({ id: 'app.months.oct' }),
        intl.formatMessage({ id: 'app.months.nov' }),
        intl.formatMessage({ id: 'app.months.dec' })
      ],
      weekdays: [
        intl.formatMessage({ id: 'app.daysOfWeek.sun' }),
        intl.formatMessage({ id: 'app.daysOfWeek.mon' }),
        intl.formatMessage({ id: 'app.daysOfWeek.tue' }),
        intl.formatMessage({ id: 'app.daysOfWeek.wed' }),
        intl.formatMessage({ id: 'app.daysOfWeek.thu' }),
        intl.formatMessage({ id: 'app.daysOfWeek.fri' }),
        intl.formatMessage({ id: 'app.daysOfWeek.sat' })
      ],
      shortMonths: [
        intl.formatMessage({ id: 'app.monthsShort.jan' }),
        intl.formatMessage({ id: 'app.monthsShort.feb' }),
        intl.formatMessage({ id: 'app.monthsShort.mar' }),
        intl.formatMessage({ id: 'app.monthsShort.apr' }),
        intl.formatMessage({ id: 'app.monthsShort.may' }),
        intl.formatMessage({ id: 'app.monthsShort.jun' }),
        intl.formatMessage({ id: 'app.monthsShort.jul' }),
        intl.formatMessage({ id: 'app.monthsShort.aug' }),
        intl.formatMessage({ id: 'app.monthsShort.sep' }),
        intl.formatMessage({ id: 'app.monthsShort.oct' }),
        intl.formatMessage({ id: 'app.monthsShort.nov' }),
        intl.formatMessage({ id: 'app.monthsShort.dec' })
      ],
      shortWeekdays: [
        intl.formatMessage({ id: 'app.daysOfWeekShort.sun' }),
        intl.formatMessage({ id: 'app.daysOfWeekShort.mon' }),
        intl.formatMessage({ id: 'app.daysOfWeekShort.tue' }),
        intl.formatMessage({ id: 'app.daysOfWeekShort.wed' }),
        intl.formatMessage({ id: 'app.daysOfWeekShort.thu' }),
        intl.formatMessage({ id: 'app.daysOfWeekShort.fri' }),
        intl.formatMessage({ id: 'app.daysOfWeekShort.sat' })
      ]
    }
  });

  const chipIndicator = () => {
    if (heatMap.fee) {
      return (
        <Grid
          item
          spacing={2}
          className={classes.topBar}>
          <Chip
            label={intl.formatMessage({
              id: 'app.charge.board.dailyRepDemand.chipBase'
            })}
            style={{
              backgroundColor: '#bfe5bf',
              fontSize: '.95rem',
              fontWeight: 500
            }}
          />
          <Chip
            label={intl.formatMessage({
              id: 'app.charge.board.dailyRepDemand.chipIntermed'
            })}
            style={{
              backgroundColor: '#f2e5bf',
              fontSize: '.95rem',
              fontWeight: 500
            }}
          />
          <Chip
            label={intl.formatMessage({
              id: 'app.charge.board.dailyRepDemand.chipTip'
            })}
            style={{
              backgroundColor: '#f2bfbf',
              fontSize: '.95rem',
              fontWeight: 500
            }}
          />
        </Grid>
      );
    } else {
      return (
        <Grid
          item
          spacing={2}
          className={classes.topBar}>
          <Chip
            label={intl.formatMessage({
              id: 'app.charge.board.dailyRepDemand.chipBaja'
            })}
            style={{
              backgroundColor: '#78E378',
              fontSize: '.95rem',
              fontWeight: 500
            }}
          />
          <Chip
            label={intl.formatMessage({
              id: 'app.charge.board.dailyRepDemand.chipBajaMed'
            })}
            style={{
              backgroundColor: '#A4E378',
              fontSize: '.95rem',
              fontWeight: 500
            }}
          />
          <Chip
            label={intl.formatMessage({
              id: 'app.charge.board.dailyRepDemand.chipMedia'
            })}
            style={{
              backgroundColor: '#E3E378',
              fontSize: '.95rem',
              fontWeight: 500
            }}
          />
          <Chip
            label={intl.formatMessage({
              id: 'app.charge.board.dailyRepDemand.chipMediaAlt'
            })}
            style={{
              backgroundColor: '#E3A478',
              fontSize: '.95rem',
              fontWeight: 500
            }}
          />
          <Chip
            label={intl.formatMessage({
              id: 'app.charge.board.dailyRepDemand.chipAlta'
            })}
            style={{
              backgroundColor: '#E37878',
              fontSize: '.95rem',
              fontWeight: 500
            }}
          />
        </Grid>
      );
    }
  };

  const [days, setDays] = useState([]);
  const localeNav = useSelector(state => state.prefs.locale);

  useEffect(() => {
    const daysInMonth = updateYear ? moment(`${updateYear}${updateMonth}`).daysInMonth() : moment().daysInMonth();
    const currentMonth = moment().month();
    const currentYear = moment().year();
    const daysArray = [];
    moment.locale(localeNav);
    for (let i = 1; i <= daysInMonth; i++) {
      const date = moment({ year: updateYear ? updateYear : currentYear, month: updateMonth  ? updateMonth - 1: currentMonth, day: i });
      daysArray.push({
        day: i,
        weekday: date.format('ddd')
      });
    }

    setDays(daysArray);
  }, [localeNav, updateMonth, updateYear]);

  const columns2 = [
    {
      title: intl.formatMessage({ id: 'app.metersCards.card.voltageMin' }),
      field: 'min',
      cellStyle: {
        backgroundColor: '#8CBA42',
        color: '#FFF',
        textAlign: 'center',
        fontWeight: '500',
        fontSize: '.95em',
        width: '30%'
      }
    },
    {
      title: intl.formatMessage({ id: 'app.metersCards.card.voltageMax' }),
      field: 'max',
      cellStyle: {
        backgroundColor: '#D64539',
        color: '#FFF',
        textAlign: 'center',
        fontWeight: '500',
        fontSize: '.95em',
        width: '30%'
      }
    },
    {
      title: intl.formatMessage({ id: 'app.metersCards.card.voltageAve' }),
      field: 'avg',
      cellStyle: {
        backgroundColor: '#EAAB36',
        color: '#FFF',
        textAlign: 'center',
        fontWeight: '500',
        fontSize: '.95em',
        width: '30%'
      }
    }
  ];

  return (
    <div>
      <Paper className={classes.paper}>
        <Grid
          container
          lg={12}
          md={12}
          xl={12}
          xs={12}
          style={{
            width: '100%',
            justifyContent: 'space-between',
            marginBottom: theme.spacing.unit,
            alignItems: 'center',
            flexDirection: 'row',
            flexWrap: 'wrap'
          }}>
          <Chip
            label={
              valueInfo.kwh
                ? intl.formatMessage({
                  id: 'app.charge.board.dailyRepConsump.title'
                })
                : intl.formatMessage({
                  id: 'app.charge.board.dailyRepConsump.titleCost'
                })
            }
            className={classes.title}
          />
          <Grid item>
            <FormGroup row>
              <FormControlLabel
                control={
                  <Checkbox
                    color="secondary"
                    checked={valueInfo.kwh}
                    onChange={handleValue}
                    name="kwh"
                  />
                }
                label={intl.formatMessage({ id: 'app.barmenu.kwh' })}
              />
              <FormControlLabel
                control={
                  <Checkbox
                    color="secondary"
                    checked={valueInfo.money}
                    onChange={handleValue}
                    name="money"
                  />
                }
                label={intl.formatMessage({ id: 'app.barmenu.tariff' })}
              />
            </FormGroup>
          </Grid>
          <Grid item>
            <FormGroup row>
              <FormControlLabel
                control={
                  <Checkbox
                    color="secondary"
                    checked={heatMap.fee}
                    onChange={handleHM}
                    name="fee"
                  />
                }
                label={intl.formatMessage({ id: 'app.barmenu.fee' })}
              />
              <FormControlLabel
                control={
                  <Checkbox
                    color="secondary"
                    checked={heatMap.hm}
                    onChange={handleHM}
                    name="hm"
                  />
                }
                label={intl.formatMessage({ id: 'app.barmenu.headMap' })}
              />
            </FormGroup>
          </Grid>

          {chipIndicator()}
          <Button
            color="secondary"
            onClick={handleClickOpen}
            variant="contained">
            {intl.formatMessage({ id: 'app.deepview.consumption.title' })}
          </Button>
        </Grid>
        <PerfectScrollbar>
          {generalReport ? (
            <Table
              className={classes.table}
              size="small"
              style={{
                width: '100%',
                borderRight: '1px solid #7e7e7e',
                borderBottom: '1px solid #7e7e7e'
              }}>
              <TableHead>
                {generalReport != undefined || generalReport != null ? (
                  <TableRow>
                    <TableCell
                      className={classes.positionLeft}
                      style={{ borderRight: '1px solid #7e7e7e' }}>
                      {intl.formatMessage({
                        id: 'app.charge.board.dailyRepConsump.hourLab'
                      })}
                    </TableCell>

                    {days.map((day, index) => (
                      <TableCell key={index}>
                        {day.day}
                        <br />
                        {day.weekday.charAt(0).toUpperCase() +
                          day.weekday.slice(1, 3)}
                      </TableCell>
                    ))}
                    {/* {Object.values(generalReport).map((columns,index) => (
                  <TableCell >
                    {index + 1}
                  </TableCell>
                // ))} */}
                    <TableCell
                      className={classes.positionLeft}
                      style={{ borderLeft: '1px solid #7e7e7e' }}>
                      {intl.formatMessage({
                        id: 'app.charge.board.dailyRepConsump.hourLab'
                      })}
                    </TableCell>
                    <TableCell style={{ borderLeft: '1px solid #7e7e7e' }}>
                      {intl.formatMessage({
                        id: 'app.charge.board.totPerDay.min'
                      })}
                    </TableCell>
                    <TableCell>
                      {intl.formatMessage({
                        id: 'app.charge.board.totPerDay.max'
                      })}
                    </TableCell>
                    <TableCell style={{ borderLeft: '1px solid #7e7e7e' }}>
                      {intl.formatMessage({
                        id: 'app.charge.board.totPerDay.avrg'
                      })}
                    </TableCell>
                  </TableRow>
                ) : null}
              </TableHead>
              <TableBody style={{ padding: '0 !important' }}>
                {Object.values(res)
                  .sort((a, b) => a[0].hora - b[0].hora)
                  .map(row => (
                    <TableRow style={{ height: '0px' }}>
                      <TableCell
                        component="th"
                        scope="row"
                        className={classes.positionLeft}>
                        {row[0].hora >= 10 ? row[0].hora : '0' + row[0].hora}:00
                      </TableCell>
                      {row.map(row => (
                        <TableCell
                          component="th"
                          scope="row"
                          style={{
                            root: { padding: '10px 13px' },
                            backgroundColor: row.color,
                            fontWeight: row.font_weight,
                            color: row.font_color,
                            border: row.border,
                            fontSize: row.font_size
                          }}>
                          {valueInfo.kwh ? row.medicion : `$${row.cost_kwh}`}
                        </TableCell>
                      ))}
                      <TableCell
                        component="th"
                        scope="row"
                        style={{ borderRight: '1px solid #7e7e7e' }}>
                        {row[0].hora >= 10 ? row[0].hora : '0' + row[0].hora}:00
                      </TableCell>
                      <TableCell
                        component="th"
                        scope="row">
                        {row[0].min}
                      </TableCell>
                      <TableCell
                        component="th"
                        scope="row"
                        style={{
                          borderLeft: '1px solid #7e7e7e',
                          borderLeftStyle: 'dashed'
                        }}>
                        {row[0].max}
                      </TableCell>
                      <TableCell
                        component="th"
                        scope="row"
                        style={{
                          borderLeft: '1px solid #7e7e7e',
                          backgroundColor: row[0].colorProm
                        }}>
                        {row[0].prom}
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          ) : (
            <CircularProgress />
          )}
        </PerfectScrollbar>
      </Paper>

      <Dialog
        fullWidth
        maxWidth="lg"
        onClose={handleClose}
        open={open}>
        <DialogTitle>
          <Chip
            label={intl.formatMessage({ id: 'app.deepview.consumption.title' })}
            className={classes.title}
          />
        </DialogTitle>

        <DialogContent>
          <DialogContentText>
            <Typography
              className={classes.title}
              style={{ color: 'red' }}
              variant="h5">
              {errorMessage}
            </Typography>
            <Typography variant="h5">
              {' '}
              {intl.formatMessage({
                id: 'app.deepview.consumption.datesMsg'
              })}{' '}
            </Typography>
            <Grid
              style={{
                paddingTop: '1rem',
                display: 'flex',
                justifyContent: 'space-between'
              }}>
              <Grid item>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <KeyboardDatePicker
                    format="dd/MM/yyyy"
                    InputProps={{
                      onFocus: () => {
                        setShowStartDatePop(true);
                      }
                    }}
                    inputVariant="outlined"
                    KeyboardButtonProps={{
                      onFocus: e => {
                        setShowStartDatePop(true);
                      }
                    }}
                    onChange={date => handleSelectStartDate(date)}
                    open={showStartDatePop}
                    PopoverProps={{
                      disableRestoreFocus: true,
                      onClose: () => {
                        setShowStartDatePop(false);
                      }
                    }}
                    style={{ marginBottom: '10px', marginRight: '10px' }}
                    value={startDate}
                    variant="inline"
                  />
                </MuiPickersUtilsProvider>

                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <KeyboardDatePicker
                    format="dd/MM/yyyy"
                    InputProps={{
                      onFocus: () => {
                        setShowEndDatePop(true);
                      }
                    }}
                    inputVariant="outlined"
                    KeyboardButtonProps={{
                      onFocus: e => {
                        setShowEndDatePop(true);
                      }
                    }}
                    onChange={date => {
                      setEndDate(date);
                      handleSelectEndDate(date);
                    }}
                    open={showEndDatePop}
                    PopoverProps={{
                      disableRestoreFocus: true,
                      onClose: () => {
                        setShowEndDatePop(false);
                      }
                    }}
                    style={{ marginBottom: '10px', marginRight: '10px' }}
                    value={endDate}
                    variant="inline"
                  />
                </MuiPickersUtilsProvider>
              </Grid>
            </Grid>
          </DialogContentText>
          <Paper>
            <HighchartsReact
              highcharts={Highcharts}
              options={options} />
          </Paper>
          <Grid
            container
            spacing={3}
            style={{
              marginTop: '1rem',
              display: 'flex',
              justifyContent: 'center'
            }}>
            <Grid
              item
              lg={4}
              md={6}
              xs={12}
              style={{display: 'flex', alignItems: 'center', flexDirection: 'column'}}
            >
              <Chip
                label={intl.formatMessage({
                  id: 'app.deepview.consumption.chart.demandSerie.label'
                })}
                className={classes.title}
                style={{marginBottom: '1rem'}}
              />
              <MaterialTable
                columns={columns2}
                style={{width: '100%'}}
                data={[stats]}
                options={{
                  filtering: false,
                  search: false,
                  sorting: false,
                  toolbar: false,
                  paging: false,
                  rowStyle: {
                    fontSize: 15
                  },
                  headerStyle: {
                    textAlign: 'center',
                    padding: '0',
                    fontSize: '0.8em',
                    fontWeight: '900',
                    minWidth: '25%'
                  }
                }}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            color="secondary"
            onClick={handleClose}
            style={{ marginBottom: '10px', marginRight: '15px' }}
            variant="contained">
            {intl.formatMessage({ id: 'app.deepview.consumption.btnCancel' })}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
import * as reportActionTypes from 'js/constants/reportsActions-types';
import axios from 'axios';
import getToken from 'helpers/auth';
import { history } from 'helpers/history';
import Swal from 'sweetalert2'



const getconfig = () => {
  return {
    headers: { Authorization: 'Bearer ' + getToken() }
  };
};

export const getStructureReport = ( endBlocking, corpId) => {
  return async dispatch => {
    axios
      .get(`https://api.metimur.mx/api/corporative_id/${corpId}/corporative_structure`,
        {
          corpId: corpId,
          responseType: 'arraybuffer',
        },
        getconfig(),
      )
      .then(function(response) {
        const typeApp = 'application/xls';

        const downloadUrl = window.URL.createObjectURL(new Blob([response.data]  , { type: typeApp }  )    );
        const link = document.createElement('a');
        let fileName= getFileName( response.headers['content-disposition'] );
        link.href = downloadUrl;
        link.setAttribute('download',  fileName  ); //any other extension
        document.body.appendChild(link);
        link.click();
        link.remove();
        endBlocking();
      })
      .catch(function(error){
        Swal.fire({
          'icon': 'error',
          'title': 'Error',
          'text': error.response.data.message
        });
        endBlocking()
      })
  }
}

 
export const postReportHorarioStarted = ( endBlocking ,   report , corpId , group , charge  ) => {
  return async dispatch => {
   // dispatch(postMeterStarted());

   axios({
    method: 'post',
url: 'https://api.metimur.mx/api/report/excel/consumedemand' , 
data: {
    corporative_id :  corpId  ,
    meter_id :  report.meter ,
    group_ids : group   ,
    charges_ids : charge   ,
    year :  report.year ,
    month : report.month ,
    type_report : report.type   
},
headers : {
     Authorization: 'Bearer ' + getToken()
} ,
responseType: "arraybuffer"
})
.then(function (response) {
   
    let typeApp="application/zip";     
    if( group.length==1 ){
        typeApp="application/xls";      
    }
 
  const downloadUrl = window.URL.createObjectURL(new Blob([response.data]  , { type: typeApp }  )    );
        const link = document.createElement('a');
        console.log( response )
        let fileName= getFileName( response.headers["content-disposition"] );
        link.href = downloadUrl;
        link.setAttribute('download',  fileName  ); //any other extension
        document.body.appendChild(link);
        link.click();
        link.remove();
        endBlocking();

 
})
.catch(function (error) {
    console.log(error);
    endBlocking(); 

});

 

  };
};


function getFileName(  disposition ){
  let filename=null;
  if (disposition && disposition.indexOf('attachment') !== -1) {
    var filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
    var matches = filenameRegex.exec(disposition);
    if (matches != null && matches[1]) { 
      filename = matches[1].replace(/['"]/g, '');
    }
  }

  return filename;

} 



/*
export const postReportHorarioStarted = () => ({
  type: reportActionTypes.POST_REPORT_HORARIOS_STARTED
});

export const postMeterSuccess = data => {
  return {
    type: reportActionTypes.POST_REPORT_HORARIOS_SUCCESS  
  };
};

export const postMeterFail = error => ({
  type: meterActionTypes.POST_METER_FAILURE,
  state: { error }
});

 */


export const getReportTotalesStarted = (  endBlocking ,  urlParam  ) => {
  return async dispatch => {
   // dispatch(postMeterStarted());
   
   axios({
    method: 'get',
url:  urlParam  ,  
headers : {
     Authorization: 'Bearer ' + getToken()
} ,
responseType: "arraybuffer"
})
.then(function (response) {
   
    let typeApp="application/xls";     
     
 
  const downloadUrl = window.URL.createObjectURL(new Blob([response.data]  , { type: typeApp }  )    );
        const link = document.createElement('a');
        console.log( response )
        let fileName= getFileName( response.headers["content-disposition"] );
        link.href = downloadUrl;
        link.setAttribute('download',  fileName  ); //any other extension
        document.body.appendChild(link);
        link.click();
        link.remove();
        endBlocking();
       
 
})
.catch(function (error) {
    console.log(error);
    endBlocking();
   

});

 

  };
};

 
 
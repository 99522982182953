/* eslint-disable react/prop-types */
import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { getDayWithMostConsume } from 'js/actions/chargeDashboardActions';

// Externals
import classNames from 'classnames';

// Material components
import { makeStyles } from '@material-ui/core';

// Shared components
import {
  Portlet,
  PortletHeader,
  PortletLabel,
  PortletContent
} from 'components';

import {
  CalendarToday as CalendarIcon,
  TrendingUp as UpIcon
} from '@material-ui/icons';

import FilterDramaIcon from '@material-ui/icons/FilterDrama';

import { Typography, Grid } from '@material-ui/core';

// Component styles
import styles from './styles';
import { useIntl } from 'react-intl';
const useStyles = makeStyles(styles);


export default function DayWithMostConsumeChart(props) {
  const intl = useIntl();
  const classes = useStyles();
  const dispatch = useDispatch();
  const dayMostConsume = useSelector(
    state => state.chargeDashboard.dayMostConsume
  );

  const { ids, className, newYear, month, ...rest } = props;
  const rootClassName = classNames(classes.root, className);
  const year = new Date().getFullYear();
  const getCurrentMonth = () => {
    let month = new Date().getMonth() + 1;
    if (month < 10) {
      return '0' + month;
    } else {
      return month;
    }
  };

  useEffect(() => {
    if(newYear === undefined || month === undefined ){
      dispatch(getDayWithMostConsume(ids, year, getCurrentMonth()));
    } else {
      dispatch(getDayWithMostConsume(ids, newYear, month));
    }
  }, [ids, newYear, month]);

  return (
    <Portlet
      {...rest}
      className={rootClassName}
    >
      <PortletHeader noDivider>
        <PortletLabel
          title={intl.formatMessage({
            id: 'app.charge.board.highConsumDayCurrMonth.title'
          })}
        />
      </PortletHeader>
      <PortletContent>
        <Grid 
          container
          spacing={2}
        >
          <Grid
            item
            lg={6}
            md={6}
            xs={12}
          >
            <div className={classes.device}>
              <CalendarIcon className={classes.deviceIcon} />
              <Typography variant="h5">
                {intl.formatMessage({
                  id: 'app.charge.board.highConsumDayCurrMonth.date'
                })}
              </Typography>
              <Typography
                style={{ color: '#730065' }}
                variant="h4"
              >
                {dayMostConsume.day}
              </Typography>
            </div>
          </Grid>
          <Grid
            item
            lg={6}
            md={6}
            xs={12}
          >
            <div className={classes.device}>
              <UpIcon className={classes.deviceIcon} />
              <Typography variant="h5">
                {intl.formatMessage({
                  id: 'app.charge.board.highConsumDayCurrMonth.consumption'
                })}
              </Typography>
              <Typography
                style={{ color: '#FEC12C' }}
                variant="h4"
              >
                {(Math.round(dayMostConsume.consume * 100) / 100)
                  .toString()
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}{' '}
                kWh
              </Typography>
            </div>
          </Grid>
          <Grid
            item
            lg={12}
            md={12}
            xs={12}
          >
            <div className={classes.device}>
              <FilterDramaIcon className={classes.deviceIcon} />
              <Typography variant="h5">
                CO2
              </Typography>
              <Typography
                style={{ color: '#ff8024' }}
                variant="h4"
              >
                {dayMostConsume.co2}{' '}
                kg
              </Typography>
            </div>
          </Grid>
        </Grid>
        {/* <div className={classes.chartWrapper}>
          <Line
            data={data}
            options={options}
          />
        </div> */}
      </PortletContent>
      {/* <PortletFooter className={classes.portletFooter}>
        <Button
          color="primary"
          size="small"
          variant="text"
        >
          Overview <ArrowRightIcon />
        </Button>
      </PortletFooter> */}
    </Portlet>
  );
}

import React, { useState } from 'react';

// Material helpers
import { Dialog, DialogActions, DialogContent, DialogTitle, Button, Chip, Link } from '@material-ui/core';

// Material components
import { Grid } from '@material-ui/core';

// Shared layouts
import { Dashboard as DashboardLayout } from 'layouts';

// Custom components
import { ProductCard,Password } from './components';

import { Container } from 'react-floating-action-button';
import { Link as LinkFloat } from 'react-floating-action-button';

// Component styles
import styles from './styles';
import { makeStyles } from '@material-ui/styles';
import { useIntl } from 'react-intl';
import theme from 'theme';
import { useDispatch } from 'react-redux';
import { importFile } from 'js/actions/groupActions';
const useStyles = makeStyles(styles);


export default function HardwareHome(props) {
  const intl = useIntl();
  const classes = useStyles();
  const dispatch = useDispatch();
  const {meterId} = props.match.params;
  const [open, setOpen] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);

  const handleCarge = () => {
    setOpen(true);
  }

  const handleFileChange = (event) => {
    setSelectedFile(event.target.files[0]);
  };

  const handleFileUpload = () => {
    const formData = Object.assign(selectedFile, {
      preview: URL.createObjectURL(selectedFile)
    })
  
    dispatch(importFile(formData));
    setOpen(false);
    setSelectedFile(null);
  };


  const options = [
    {
      id: 0,
      destination: `/concentradors/${meterId}`,
      fontIcon: 'fas fa-charging-station',
      meterId: meterId,
      productname: intl.formatMessage({id: 'app.groupsCards.hardwareConfig.concentrators'})
    },
    {
      id: 1,
      destination: `/allcards/${meterId}`,
      fontIcon: 'fas fa-microchip',
      productname: intl.formatMessage({id: 'app.groupsCards.hardwareConfig.cards'})
    },
    {
      id: 2,
      destination: `/allcardsHomework/${meterId}`,
      fontIcon: 'fas fa-plug',
      productname: intl.formatMessage({id: 'app.groupsCards.hardwareConfig.tasks'})
    },
    {
      id: 3,
      destination: `/powerfactor/${meterId}`,
      fontIcon: 'fa fa-square',
      productname: intl.formatMessage({id: 'app.groupsCards.hardwareConfig.powerFact'})
    },
    {
      id: 4,
      destination: `/voltage/${meterId}`,
      fontIcon: 'fas fa-broadcast-tower',
      productname: intl.formatMessage({id: 'app.groupsCards.hardwareConfig.voltage'})
    },
    // {
    //   id: 5,
    //   fontIcon: 'fas fa-upload',
    //   onClick: ()=>handleCarge(),
    //   productname: 'Carga Masiva'
    // },
  ]

  return (
    <DashboardLayout title="Configuracion de Hardware">
      <div className={classes.root}>

        <Grid
          className={classes.content}
          container
          spacing={3}
        >
          <Grid
            item
            lg={12}
            md={12}
            xs={12}
          >
            <div className={classes.table}>
              {/* <ChargeGeneralTable
              group={this.props.location.state.group}
            /> */}
            </div>
          </Grid>
        </Grid>

        <div className={classes.content}>
          <Grid
            container
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              flexDirection: 'row',
              flexWrap: 'wrap'
            }}
          >
            {options.map((option, index) => (
              <Grid
                index={index}
                item
                key={index}
                onClick={option.onClick}
                style={{maxWidth: '16%'}}
              >
                <ProductCard
                  className={classes.option}
                  destination={option.destination}
                  fontIcon={option.fontIcon}
                  meterId={option.meterId}
                  productname={option.productname}
                />
              </Grid>
            ))}
          </Grid>
        </div>

        <div className={classes.root}>
          <Grid
            container
            spacing={4}
          >
            <Grid
              item
              md={12}
              xs={12}
            >
              <Password />
            </Grid>
          </Grid>
        </div>
        <Container
          styles={{marginRight: '-20px',
            marginBottom: '-20px'}}
        
        >
          <LinkFloat
            //href={`/concentrador/${meterId}/group/${group.id}`}
            icon="fa fa-charging-station"
            styles={{
              backgroundColor: '#00AFEF',
              color: '#fff',
              width: '60px',
              height: '60px',
              fontSize: '1.5rem'
            }}
            tooltip={intl.formatMessage({id: 'app.groupsCards.hardwareConfig.toolTip.concentrator'})}
          />
          <Button
            icon="fa fa-cog"
            rotate
            styles={{
              backgroundColor: '#C69100',
              color: '#fff',
              width: '80px',
              height: '80px',
              fontSize: '2rem'
            }}
          />
        </Container>
        <Dialog
          aria-describedby="alert-dialog-description"
          aria-labelledby="alert-dialog-title"
          className={classes.dialog}
          fullWidth
          maxWidth="sm"
          onClose={() => setOpen(false)}
          open={open}
        >
          <DialogTitle>
            <Chip
              className={classes.chip}
              label="Subir archivos"
            />
          </DialogTitle>
          <DialogContent
            style={{display: 'flex', alignItems: 'center', justifyContent: 'center', height: '300px', position: 'relative'}}
          >
            <Link
              color="secondary"
              href="https://documentos-metimur.s3.us-west-2.amazonaws.com/importandor/Importador_de_Grupos_Tarjetas_y_Cargas_.pdf"
              style={{position: 'absolute', top: '1rem', fontWeight: 700, fontSize: '1.2rem', fontFamily: 'Roboto, Helvetica, Arial' }}
              target="blank"
              underline="none"
            >Ver Instrucciones</Link>
            <input
              accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
              id="fileSelect"
              onChange={handleFileChange}
              style={{backgroundColor: theme.palette.background.paper}}
              type="file"
            />
          </DialogContent>
          <DialogActions>
            <Button
              color="secondary"
              disabled={!selectedFile}
              onClick={handleFileUpload}
              variant="contained"
            >
              Subir Archivos
            </Button>
            <Button
              color="secondary"
              onClick={() => setOpen(false)}
              variant="contained"
            >
              Cancelar
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    </DashboardLayout>
  );
}
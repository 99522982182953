/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';

// Externals
import { useSelector, useDispatch } from 'react-redux';
import { Typography } from '@material-ui/core';
import PerfectScrollbar from 'react-perfect-scrollbar';

//import { getGeneralReport } from 'js/actions/chargeDashboardActions';

// Material helpers
import {
  makeStyles,
  TableRow,
  TableCell,
  TableHead,
  Paper,
  Table,
  TableBody,
  Chip
} from '@material-ui/core';

// Component styles
import styles from './styles';
import { useIntl } from 'react-intl';
import moment from 'moment';
const useStyles = makeStyles(styles);

export default function OnOff(props) {
  const intl = useIntl();
  const classes = useStyles();
  const dispatch = useDispatch();
  const { ids, heatMap, valueInfo, updateMonth, updateYear } = props;
  const onOff = useSelector(
    state => state.chargeDashboard.generalReport.onoff
  );

  const [days, setDays] = useState([]);
  const localeNav = useSelector(state => state.prefs.locale);

  useEffect(() => {
    const daysInMonth = updateYear ? moment(`${updateYear}${updateMonth}`).daysInMonth() : moment().daysInMonth();
    const currentMonth = moment().month();
    const currentYear = moment().year();
    const daysArray = [];
    moment.locale(localeNav);
    for (let i = 1; i <= daysInMonth; i++) {
      const date = moment({ year: updateYear ? updateYear : currentYear, month: updateMonth  ? updateMonth - 1: currentMonth, day: i });
      daysArray.push({
        day: i,
        weekday: date.format('ddd')
      });
    }

    setDays(daysArray);
  }, [localeNav, updateMonth, updateYear]);


  return (
    <div>
      <Paper className={classes.paper}>
        <Chip
          className={classes.title}
          label="Tiempos de Operación"
        />
        <PerfectScrollbar>
          <Table
            className={classes.table}
            size="small"
            style={{ width: '100%' }}
          >
            <TableHead>
              {onOff !== undefined && onOff !== null ? (
                <TableRow>
                  <TableCell className={classes.positionLeft}>
                    Estatus
                  </TableCell>
                  {days.map((day, index) => (
                    <TableCell key={index}>
                      {day.day}
                      <br />
                      {day.weekday.charAt(0).toUpperCase() + day.weekday.slice(1, 3)}
                    </TableCell>
                  ))}
                </TableRow>
              ) : null}
            </TableHead>
            <TableBody style={{ padding: '0 !important' }}>
              {onOff !== undefined && onOff !== null ? (
                <TableRow style={{ height: '0px' }}>
                  <TableCell
                    className={classes.positionLeft}
                    component="th"
                    scope="row"
                  >
                    Activa
                  </TableCell>
                  {Object.values(onOff).map((item, index) => (
                    <TableCell
                      component="th"
                      key={index}
                      scope="row"
                    >
                      {item ? item.on > 9 ? `${item.on}:00` : `0${item.on}:00` : '00:00'}
                    </TableCell>
                  ))}
                </TableRow>
              ) : null}
              {onOff !== undefined && onOff !== null ? (
                <TableRow style={{ height: '0px' }}>
                  <TableCell
                    className={classes.positionLeft}
                    component="th"
                    scope="row"
                  >
                    Inactiva
                  </TableCell>
                  {Object.values(onOff).map((item, index) => (
                    <TableCell
                      component="th"
                      key={index}
                      scope="row"
                    >
                      {item ? item.off > 9 ? `${item.off}:00` : `0${item.off}:00` : '00:00'}
                    </TableCell>
                  ))}
                </TableRow>
              ) : null}
            </TableBody>

          </Table>
        </PerfectScrollbar>
      </Paper>
    </div>
  );
}

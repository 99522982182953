import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';
import { Line } from 'react-chartjs-2';
import {
  Portlet,
  PortletHeader,
  PortletLabel,
  PortletContent,
} from 'components';
import { makeStyles } from '@material-ui/styles';
// import { useIntl } from 'react-intl';
import moment from 'moment';
import { getAngle } from 'js/actions/chargeDashboardActions';
import styles from './styles';

const useStyles = makeStyles(styles);

const ReactiveVsActive = ({ concentratorId, charge, fp, ...rest }) => {
  // const intl = useIntl();
  const classes = useStyles();
  const dispatch = useDispatch();
  const angle = useSelector(state => state.chargeDashboard.angle);
  const localeNav = useSelector(state => state.prefs.locale);

  const charge_id = charge ? charge.id : 0;

  const [days, setDays] = useState([]);
  const [res, setRes] = useState([]);

  // Get current month for API call
  const getCurrentMonth = () => moment().format('YYYYMM');

  // Fetch angle data
  useEffect(() => {
    if (fp && concentratorId && charge_id) {
      dispatch(getAngle(fp, concentratorId, charge_id, getCurrentMonth()));
    }
  }, [dispatch, fp, concentratorId, charge_id]);
 
  // Process angle data into totals
  useEffect(() => {
    if (angle) {
      const processTableData = data =>
        Object.entries(data).map(([date, value]) => ({
          date,
          total: value.total || 0,
          total_kwh: value.total_kwh || 0,
        }));
      setRes(processTableData(angle));
    }
  }, [angle]);
 
  // Generate days of the current month
  useEffect(() => {
    const daysInMonth = moment().daysInMonth();
    const currentMonth = moment().month();
    const currentYear = moment().year();
    const daysArray = [];
 
    moment.locale(localeNav);
 
    for (let i = 1; i <= daysInMonth; i++) {
      const date = moment({ year: currentYear, month: currentMonth, day: i });
      daysArray.push({
        day: i,
        weekday: date.format('ddd'),
        date: date.format('YYYYMMDD'),
      });
    }
    setDays(daysArray);
  }, [localeNav]);
 
  const total = res ? res.map(item => item.total) : [];
  const total_kwh = res ? res.map(item => item.total_kwh) : [];
 
  // Determinar el máximo dinámico
  const maxYValue = Math.max(
    Math.max(...total, 0),
    Math.max(...total_kwh, 0)
  ) + 10;

  const data = {
    labels: days.map(day => `${day.weekday} ${day.day}`),
    datasets: [
      {
        data: total,
        label: 'Potencia Reactiva',
        fill: false,
        backgroundColor: '#730065',
        borderColor: '#730065',
        borderWidth: 2,
      },
      {
        data: total_kwh,
        label:'Potencia Activa',
        fill: false,
        backgroundColor: '#00AFEF',
        borderColor: '#00AFEF',
        borderWidth: 2,
      },
    ],
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    legend: { display: true },
    tooltips: {
      callbacks: {
        label: (tooltipItem, data) =>
          tooltipItem.datasetIndex === 0
            ? `${data.datasets[0].data[tooltipItem.index].toFixed(2)} kVArh`
            : `${data.datasets[1].data[tooltipItem.index].toFixed(2)} kW`,
      },
    },
    scales: {
      xAxes: [
        {
          offset: true,
        },
      ],
      yAxes: [
        {
          position: 'left',
          ticks: {
            beginAtZero: true,
            max: maxYValue, // Límite dinámico basado en los datos
          }
        },
      ],
    },
  };

  return (
    <Portlet
      {...rest}
      className={classNames(classes.root)}
    >
      <PortletHeader noDivider>
        <PortletLabel
          title="Reporte Diario de Potencia Reactiva vs Potencia Activa"
        />
      </PortletHeader>
      <PortletContent>
        <div className={classes.chartWrapper}>
          <Line
            data={data}
            options={options}
          />
        </div>
      </PortletContent>
    </Portlet>
  );
};

export default ReactiveVsActive;

import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
// import regression from 'regression';

// Externals
import classNames from 'classnames';
import { Line } from 'react-chartjs-2';

// Shared components
import {
  Portlet,
  PortletHeader,
  PortletLabel,
  PortletContent,
} from 'components';


// Component styles
import styles from './styles';
import { makeStyles } from '@material-ui/styles';
import { useIntl } from 'react-intl';
import moment from 'moment';

const useStyles = makeStyles(styles);

// Function to calculate the linear regression
// function calculateTrendLine(data) {
//   const points = data.map((value, index) => [index + 1, value]);
//   const result = regression.linear(points);
//   const slope = result.equation[0];
//   const intercept = result.equation[1];
//   return data.map((_, i) => {
//     const trendValue = slope * (i + 1) + intercept;
//     return Math.max(0, trendValue);
//   });
// }

export default function ConsumptionAndDemandChart(props) {
  const intl = useIntl()
  const classes = useStyles();
  const { className, weather,updateYear,updateMonth, ...rest } = props;
  const year = new Date().getFullYear();
  const month = new Date().getMonth() + 1;
  const daysInMonth = moment().daysInMonth();
  const [weatherData,setWeatherData] = useState({});
  const [maxTemp, setMaxTemp] = useState([]);
  const [minTemp, setMinTemp] = useState([]);
  const generalReport = useSelector(
    state => state.chargeDashboard.generalReport.total_per_day_kw
  );

  const [days, setDays] = useState([]);
  const localeNav = useSelector(state => state.prefs.locale);

  useEffect(() => {
    const daysInMonth = updateYear ? moment(`${updateYear}${updateMonth}`).daysInMonth() : moment().daysInMonth();
    const currentMonth = moment().month();
    const currentYear = moment().year();
    const daysArray = [];
    moment.locale(localeNav);
    for (let i = 1; i <= daysInMonth; i++) {
      const date = moment({ year: updateYear ? updateYear : currentYear, month: updateMonth  ? updateMonth - 1: currentMonth, day: i });
      daysArray.push({
        day: i,
        weekday: date.format('ddd')
      });
    }

    setDays(daysArray);
  }, [localeNav, updateMonth, updateYear]);

  useEffect(() => {
    // Llenar weatherData con los datos recibidos de la API
    const newWeatherData = {}; // <-- Crea un nuevo objeto aquí
    for (const dateKey in weather) {
      newWeatherData[dateKey] = weather[dateKey];
    }

    // Rellenar los días restantes con valores predeterminados
    for (let day = 1; day <= daysInMonth; day++) {
      const dateKey = `${year}${String(month).padStart(2, '0')}${String(day).padStart(2, '0')}`;
      if (!(dateKey in newWeatherData)) {
        newWeatherData[dateKey] = {
          temp: 0,
          temp_min: 0,
          temp_max: 0,
        };
      }
    }

    setWeatherData(newWeatherData); // <-- Actualiza el estado con el nuevo objeto
  }, [weather]); 

  useEffect(() => {
    if (weatherData && weather) {
      const newMinTemps = [];
      const newMaxTemps = [];
  
      Object.values(weatherData).forEach(data => {
        newMinTemps.push(data.temp_min);
        newMaxTemps.push(data.temp_max);
      });
  
      // Actualiza el estado una sola vez al final del bucle
      setMinTemp(newMinTemps);
      setMaxTemp(newMaxTemps);
    }
  }, [weatherData, weather]);

  const labels = days.map(day => `${day.weekday.charAt(0).toUpperCase() + day.weekday.slice(1, 3)} ${day.day}`);

  const data = {
    labels: labels,
    datasets:[
      {
        data : [],
        label:intl.formatMessage({id: 'app.charge.board.demandPerDay.dataSet.labels.demand'}),
        fill: false,
        lineTension: 0.3,
        backgroundColor: '#909090',
        borderColor: '#909090',
        borderWidth: 4,
        borderCapStyle: 'butt',
        yAxisID: 'y-axis-1'
      },
      {
        data: maxTemp,
        label:intl.formatMessage({id: 'app.charge.board.tempMax.title'}),
        fill: false,
        lineTension: 0.3,
        backgroundColor: '#ED685E',
        borderColor: '#ED685E',
        borderWidth: 1,
        borderCapStyle: 'butt',
        yAxisID: 'y-axis-2'
      },
      {
        data: minTemp,
        label:intl.formatMessage({id: 'app.charge.board.tempMin.title'}),
        fill: false,
        lineTension: 0.3,
        backgroundColor: '#00AFEF',
        borderWidth: 1,
        borderColor: '#00AFEF',
        borderCapStyle: 'butt',
        yAxisID: 'y-axis-2'
      }
      // {
      //   data: [],
      //   label: intl.formatMessage({ id: 'app.charge.board.dailyAcumConsump.dataSet.tendency' }),
      //   fill: false,
      //   lineTension: 0.3,
      //   backgroundColor: 'rgba(255,99,132,0.4)',
      //   borderColor: 'rgba(255,99,132,1)',
      //   borderCapStyle: 'butt',
      //   borderDash: [5, 5],
      // },
    ],
  };

  if (generalReport) {
    const maxValues = [];
    Object.keys(generalReport).forEach((key) => {
      const element = generalReport[key];
      const day = parseInt(key, 10) - 1;
      data.datasets[0].data[day] = parseFloat(element.max);
      if (parseFloat(element.max) > 0) {
        maxValues.push(parseFloat(element.max));
      }
    });
    // const trendLine = calculateTrendLine(maxValues);

    // trendLine.forEach((value, index) => {
    //   data.datasets[1].data[index] = value;
    // });

    // const result = regression.linear(trendLine.map((val, idx) => [idx + 1, val]));
    // const slope = result.equation[0];
    // const intercept = result.equation[1];

    // for (let i = maxValues.length; i < 31; i++) {
    //   const y = slope * (i + 1) + intercept;
    //   data.datasets[1].data[i] = Math.max(0, y);
    // }
  }


  const limit = data ? Math.ceil((Math.max(...data.datasets[0].data) + 5) / 10) * 10 : 100;


  const options = {
    responsive: true,
    maintainAspectRatio: false,
    legend: { display: true },
    animation: {
      onComplete: function () {
        const chartInstance = this.chart,
          ctx = chartInstance.ctx;
        ctx.textAlign = 'center';
        ctx.fillStyle = 'rgba(0, 0, 0, 1)';
        ctx.textBaseline = 'bottom';

        // Solo mostrar los datos del dataset 0 (demand)
        const demandDataset = chartInstance.data.datasets[0]; 
        chartInstance.controller.getDatasetMeta(0).data.forEach(function(bar, index) {
          const value = demandDataset.data[index]; // Valor de demand
          ctx.fillText(`${value.toFixed(2)}`, bar._model.x, bar._model.y - 5);
        });
      }
    },
    tooltips: {
      shared: true,
      callbacks: {
        label: function (tooltipItem, data) {
          const dataset = data.datasets[tooltipItem.datasetIndex];
          const value = dataset.data[tooltipItem.index];
          if (tooltipItem.datasetIndex === 0) { 
            return `${value.toFixed(2)} kW`; // Demand dataset
          } else {
            return `${value.toFixed(2)}°C`; // Temperature datasets
          }
        }
      }
    },
    scales: {
      yAxes: [
        {
          id: 'y-axis-1',
          display: true,
          position: 'right',
          ticks: {
            callback: function (value) {
              return `${value.toFixed(0)} kW`;
            },
            beginAtZero: true,
            max: limit,
          },
          gridLines: {
            display: true
          }
        },
        {
          id: 'y-axis-2',
          position: 'left',
          ticks: {
            callback: function (value) {
              return `${value.toFixed(0)}°C`;
            },
            beginAtZero: true,
          },
          gridLines: {
            display: false
          }
        },
      ],
    },
  };

  // const dataHours = [];
  // let obj;
 

  /*
if(  generalReport ){

  Object.values(generalReport).forEach((element, index) => {
    element.forEach(element2=>{
      obj = {
        hora: element2.Hora,
        medicion: element2.Kw,
        color: element2.color
      };
      dataHours.push(obj);
    })

    //data.datasets[0].data.push( Math.max.apply(Math, element.map(function(o) { return o.Kw; })).toFixed(2))
  })


} */




  /*
  const res = dataHours.reduce((acc, curr) => {
    if (!acc[curr.hora]) acc[curr.hora] = []; //If this type wasn't previously stored
    acc[curr.hora].push(curr);
    return acc;
  }, {}); */


  /*
  Object.values(res).sort((a, b) => a[0].hora - b[0].hora).forEach(element =>{
    data.labels.push(`${element[0].hora}`)

    let consumesARRAY = []

    element.forEach(element2 => {
      consumesARRAY.push(element2.medicion)
    });

    console.log(consumesARRAY)

    data.datasets.push(
      {
        label:'Demanda',
        fill: false,
        lineTension: 0.3,
        backgroundColor: 'rgba(75,192,192,0.4)',
        borderColor: 'rgba(75,192,192,1)',
        borderCapStyle: 'butt',
        data: consumesARRAY
      }
    )
  })

  
*/
  

  const rootClassName = classNames(classes.root, className);

  return (
    <Portlet
      {...rest}
      className={rootClassName}
    >
      <PortletHeader noDivider>
        <PortletLabel title={intl.formatMessage({id: 'app.charge.board.demandPerDay.title'})} />
        {/* <PortletToolbar>
          <Button
            className={classes.dropdownButton}
            size="small"
            variant="text">
            Septiembre
          </Button>
        </PortletToolbar> */}
      </PortletHeader>
      <PortletContent>
        <div className={classes.chartWrapper}>
          <Line
            data={data}
            options={options}
          /> 
        </div>
      </PortletContent>
      {/* <PortletFooter className={classes.portletFooter}>
          <Button
            color="primary" 
            size="small"
            variant="text"
          >
            Overview <ArrowRightIcon />
          </Button>
        </PortletFooter> */}
    </Portlet>
  );
}

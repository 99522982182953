/* eslint-disable no-unused-vars */
/* eslint-disable react/jsx-closing-bracket-location */
/* eslint-disable no-class-assign */
/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Breadcrumbs, withStyles } from '@material-ui/core';
import {
  Grid,
  Box,
  Typography,
  Link as LinkMaterial
} from '@material-ui/core';
import { Dashboard as DashboardLayout } from 'layouts';
import {
  Budget,
  Users,
  Profit,
  ConsumptionAndDemandChart,
  MonthWithMostConsumeChart,
  ComparativeYearConsumeChart,
  DayWithMostConsumeChart, 
  ChargeWithMostConsumeChart,
  Measurements,
  MonthChart, 
  ContractedDemand,
  FPGauge,
  ConsumptionAndWeightChart
} from './components';
import FPDailyAvrgChart from './components/FPDailyAvrgChart';
import { injectIntl } from 'react-intl';
import AmountByGroupChart from './components/AmountByGroupChart';
import theme from 'theme';
import { useSelector } from 'react-redux';
import { isAllowed } from 'helpers';
import { NavLink } from 'react-router-dom/cjs/react-router-dom';

const styles = theme => ({
  root: {
    padding: theme.spacing.unit * 4
  },
  item: {
    height: '100%'
  },
  breadcrumb: {
    fontSize: '1em',
    marginBottom: theme.spacing.unit * 2 
  },
  bugets: {
    display: 'flex',
    flexDirection: 'row',
    marginBottom: '20px',
    justifyContent: 'space-between',
    width: '100%',
    [theme.breakpoints.down('md')]: {
      gap: '0',
      flexWrap: 'wrap',
    }
  },
  itemsBugetFive: {
    height: '100vh',
    maxHeight: '135px',
    width: '19.5%',
    [theme.breakpoints.down('md')]: {
      width: '49.5%',
      marginBottom: '10px'
    },
    [theme.breakpoints.down('sm')]: {
      width: '100%'
    }
  },
  lastItemsBugetFive: {
    height: '100vh',
    maxHeight: '135px',
    width: '19.5%',
    [theme.breakpoints.down('md')]: {
      width: '100%'
    }
  },
  itemsBugetFour: {
    height: '100vh',
    maxHeight: '135px',
    width: '24.5%',
    [theme.breakpoints.down('md')]: {
      width: '49.5%',
      marginBottom: '10px'
    },
    [theme.breakpoints.down('sm')]: {
      width: '100%'
    }
  }
});

const Dashboard = (props) => {
  const { classes, intl } = props;

  const { corpId, meterId } = props.match.params;
  const ids = { corpId, meterId };

  const user = useSelector(state => state.user.user);

  const corpAdmin = useSelector(state=>state.user.corporative)
  const corpUser = user.corporative
  const corporativeId = corpUser === undefined || corpUser === null ? corpAdmin.id : corpUser.id
  const [meterFp, setMeterFp] = useState();

  const storedCorpName = localStorage.getItem(`corpName_${corporativeId}`);

  useEffect(() => {
    if (corpAdmin && corpAdmin.name) {
      localStorage.setItem(`corpName_${corporativeId}`, corpAdmin.name);
    }
  }, [corpAdmin, corporativeId]);

  const corpName = corpAdmin && corpAdmin.name ? corpAdmin.name : storedCorpName;

  const { meter } = props.location.state;
  const contractedDemand = meter.contracted_demand || 0;  
  const showContractedDemand = parseInt(contractedDemand) > 0;

  const displayContractedDemand = () => {
    if (showContractedDemand) {
      return (
        <ContractedDemand
          className={classes.itemsBugetFive}
          corpId={corpId}
          meterId={meterId}
        /> 
      );
    } else {
      return null;
    }
  };

  return (
    <DashboardLayout
      chargeName={meter ? meter.name : null}
      title="Indicadores De Medidor">
      <div className={classes.root}>
        <Typography
          style={{ color: '#A53693', marginBottom: theme.spacing.unit * 2 }}
          variant="h2"
        >
          {intl.formatMessage({ id: 'app.meter.board.title' })}
        </Typography>
        <Breadcrumbs
          aria-label="breadcrumb"
          className={classes.breadcrumb}
          separator=":"
        >
          {/* {isAllowed(user.permissions, ['corporative_read']) &&
            <LinkMaterial
              color="inherit"
              component={NavLink}
              to="/home"
            >
              {intl.formatMessage({ id: 'app.meters.nav.corp' })}
            </LinkMaterial>} */}
          <LinkMaterial
            color="inherit"
            component={NavLink}
            style={{color:'#A53693'}}
            to={`/corporative/${corpId}`}
          >
            {intl.formatMessage({id: 'app.meters.nav.meters'})}
          </LinkMaterial>
          <Typography variant="h5">{meter.name}</Typography>
        </Breadcrumbs>
        <Box
          className={classes.bugets}
          container
        >
          <Budget
            className={showContractedDemand ? classes.itemsBugetFive : classes.itemsBugetFour}
            ids={ids}
          />
          <Users className={showContractedDemand ? classes.itemsBugetFive : classes.itemsBugetFour} />
          {displayContractedDemand()}
          <Profit className={showContractedDemand ? classes.itemsBugetFive : classes.itemsBugetFour} />
          <Measurements
            className={showContractedDemand ? classes.lastItemsBugetFive : classes.itemsBugetFour}
            meter={meter}
            meterId={meterId}
          />
        </Box>
        <Grid
          container
          spacing={2}
        >
          <Grid
            item
            lg={8}
            md={12}
            xl={9}
            xs={12}
          >
            <ConsumptionAndDemandChart
              className={classes.item}
              ids={ids}
            />
          </Grid>
          <Grid
            item
            lg={4}
            md={12}
            xl={3}
            xs={12}
          >
            <MonthWithMostConsumeChart
              className={classes.item}
              ids={ids}
            />
          </Grid>
          <Grid
            item
            lg={8}
            md={12}
            xl={9}
            xs={12}
          >
            <ComparativeYearConsumeChart
              className={classes.item}
              ids={ids}
            />
          </Grid>
          <Grid
            item
            lg={4}
            md={12}
            xl={3}
            xs={12}
          >
            <div style={{ marginBottom: '10px' }}>
              <DayWithMostConsumeChart
                className={classes.item}
                ids={ids}
              />
            </div>
            <div>
              <ChargeWithMostConsumeChart
                className={classes.item}
                ids={ids}
              />
            </div>
          </Grid>
          <Grid
            item
            lg={12}
            md={12}
            xl={12}
            xs={12}
          >
            <MonthChart
              className={classes.item}
              ids={ids}
            />
          </Grid>
          <Grid
            item
            lg={8}
            md={12}
            xl={9}
            xs={12}
          >
            <FPDailyAvrgChart
              className={classes.item}
              ids={ids}
              setMeterFp={setMeterFp}
            />
          </Grid>              
          <Grid
            item
            lg={4}
            md={12}
            xl={3}
            xs={12}
          >
            <FPGauge
              className={classes.item}
              ids={ids}
              meterFp={meterFp}
            />
          </Grid>
          <Grid
            item
            lg={12}
            md={12}
            xl={12}
            xs={12}
          >
            <AmountByGroupChart
              className={classes.item}
              ids={ids}
            />
          </Grid>
        </Grid>
      </div>
    </DashboardLayout>
  );
};

Dashboard.propTypes = {
  classes: PropTypes.object.isRequired
};

export default injectIntl(withStyles(styles)(Dashboard));

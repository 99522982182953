/* eslint-disable react/jsx-sort-props */
/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

// Externals
import classNames from 'classnames';

// Material helpers
import { Box, Button, Chip, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, FormControlLabel, FormLabel, Grid, InputAdornment, MenuItem, Paper, Radio, RadioGroup, Select, TextField, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import Swal from 'sweetalert2';

// Material icons
import { Receipt as ReceiptIcon } from '@material-ui/icons';


// Component styles
import styles from './styles';
import { getPeriodReport, getReceipt, sendReceipByPeriod } from 'js/actions/chargeDashboardActions';
import { useSelector ,  useDispatch } from 'react-redux';
import { useIntl } from 'react-intl';

const useStyles = makeStyles(styles);

export default function Profit(props) {
  const intl = useIntl()
  const classes = useStyles();
  
  const dispatch = useDispatch()
  const { className, month, year, ...rest } = props;
  const { chargeId } = props.ids;
  const rootClassName = classNames(classes.root, className);
  const receiptURL = useSelector(state => state.chargeDashboard.receipt);
  let loading = useSelector(state => state.chargeDashboard.loading)
  let byPeriod = useSelector(state => state.chargeDashboard.byPeriod);
  const [openModal, setOpenModal] = useState(false);
  const [value, setValue] = useState('historical');
  const [startDate , setStartDate] = useState(new Date());
  const [endDate , setEndDate] = useState(new Date());
  const [formatPhone, setFormatPhone] = useState('');
  const [phone, setPhone] = useState('');
  const [email, setEmail] = useState('');
  const [country, setCountry] = useState('mexico');
  const [showInfo, setShowInfo] = useState(false);
  const [sendEmail, setSendEmail] = useState('no')

  //  const year = new Date().getFullYear();

  const getLastMonth = () => {
    const month = new Date()

    const lastMonth = month.getMonth()

    switch (lastMonth-1) {
      case 0:
        return 1
      case 1:
        return 2
      case 2:
        return 3
      case 3:
        return 4
      case 4:
        return 5
      case 5:
        return 6
      case 6:
        return 7
      case 7:
        return 8
      case 8:
        return 9
      case 9:
        return 10
      case 10:
        return 11
      case 11:
        return 12
      default:
        break;
    }
  };

  const getReceiptData = chargeId => {

    let month = new Date().getMonth();
    let year = new Date().getFullYear();

    month++;
    if( month==1 ){
      month=12 ;
      year--;
    }else {
      month=month-1;
    }

    dispatch(getReceipt(chargeId,year, month ))

  }

  const handleReceipt = () => {
    if(month === null && year === null){
      getReceiptData(chargeId);
    } else {
      dispatch(getReceipt(chargeId, year, parseInt(month)))
    }
  }

  const countryCodes = {
    colombia: { code: '+57', maxLength: 10 },
    mexico: { code: '+52', maxLength: 10 },
  };

  const handleCountryChange = (event) => {
    setCountry(event.target.value);
    setFormatPhone('');
  };

  const handlePhoneChange = (event) => {
    const rawPhone = event.target.value.replace(/[^\d]/g, ''); // Eliminar caracteres no numéricos
    let formattedPhone = rawPhone;
    setPhone(countryCodes[country].code + rawPhone);


    // Formatear el número según el país seleccionado
    if (country === 'colombia') {
      formattedPhone = rawPhone.replace(/^(\d{3})(\d{3})(\d{4})$/, '($1) $2-$3'); // Formato (NNN) NNN-NNNN
    } else if (country === 'mexico') {
      formattedPhone = rawPhone.replace(/^(\d{2})(\d{4})(\d{4})$/, '($1) $2-$3'); // Formato (NN) NNNN-NNNN
    }

    // Limitar la longitud del número según el país
    if (rawPhone.length <= countryCodes[country].maxLength) {
      setFormatPhone(formattedPhone);
    }
  };

  const formatDate = (date) => {
    const d = new Date(date);
    const year = d.getFullYear();
    const month = (`0${d.getMonth() + 1}`).slice(-2);
    const day = (`0${d.getDate()}`).slice(-2);
    const hours = (`0${d.getHours()}`).slice(-2);
    const minutes = (`0${d.getMinutes()}`).slice(-2);
    const seconds = (`0${d.getSeconds()}`).slice(-2);
    return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
  };

  const handleModal = () => {
    setOpenModal(!openModal);
    setValue('historical');
    setStartDate(new Date());
    setEndDate(new Date());
    setFormatPhone('');
    setPhone('');
    setCountry('mexico');
    setShowInfo(false);
    setEmail('');
    setSendEmail('no');
  }


  const handleRadioChange = (event) => {
    setValue(event.target.value);
  }

  const handlePeriod = (event) => {
    event.preventDefault();
  
    // Validar que la fecha de inicio sea menor a la fecha de fin
    if (new Date(startDate) >= new Date(endDate)) {
      alert('La fecha de inicio debe ser menor que la fecha de fin.');
      return;
    }
  
    // Formatear las fechas antes de enviarlas
    const formattedStartDate = formatDate(startDate);
    const formattedEndDate = formatDate(endDate);
  
    dispatch(getPeriodReport(chargeId, formattedStartDate, formattedEndDate)).then(()=>setShowInfo(true))
  };

  const handleSendWhatsapp = () => {
    // Formatear las fechas antes de enviarlas
    const formattedStartDate = formatDate(startDate);
    const formattedEndDate = formatDate(endDate);

    dispatch(sendReceipByPeriod(chargeId, formattedStartDate, formattedEndDate, phone, email))
      .then(()=>{
        Swal.fire({
          icon: 'success',
          title: 'Reporte enviado con éxito',
        })
      })
      .then(()=>handleModal())
  }

  const formattedMoney = (value) => {
    const formatter = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
    });
    return formatter.format(value)
  }

  const validateEmail = () => {
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailPattern.test(email);
  };

  const isButtonEnabled = () => {
    return formatPhone.length >= countryCodes[country].maxLength || (sendEmail === 'yes' && validateEmail());
  };
  
  return (
    <Paper
      {...rest}
      className={rootClassName}
      onClick={()=>handleReceipt()}
    >
      <Box
        className={classes.iconTable}
        onClick={handleModal}
      >
        <div className={classes.content}>
          <div className={classes.details}>
            <Typography
              className={classes.title}
              variant="body2"
            >
              {intl.formatMessage({ id: 'app.charge.board.mainHead.receiptConsult' })}
            </Typography>
          </div>
          <div className={classes.iconWrapper}>
            <ReceiptIcon className={classes.icon} />
          </div>
        </div>
        <div className={classes.footer}>

          <Typography
            className={classes.caption}
            variant="caption"
          >
            {intl.formatMessage({ id: 'app.charge.board.mainHead.receiptConsult.linkMsg' })}
          </Typography>
        </div>
      </Box>
      <Dialog
        aria-labelledby="form-dialog-title"
        fullWidth
        maxWidth="xs"
        onClose={handleModal}
        open={openModal}
      >
        <DialogTitle>
          <Chip
            className={classes.chip}
            label="Recibos"
          />
        </DialogTitle>
        <DialogContent
          style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}
        >
          <FormControl
            component="fieldset"
            style={{marginTop: '10px'}}
          >
            <FormLabel
              component="legend"
              style={{textAlign: 'center'}}
            >Elige el Recibo a Descargar</FormLabel>
            <RadioGroup
              aria-label="report"
              name="report"
              onChange={handleRadioChange}
              style={{display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', gap: '50px'}}
              value={value}
            >
              <FormControlLabel
                control={<Radio />}
                label="Mensual"
                value="receipt"
              />
              <FormControlLabel
                control={<Radio />}
                label="Por Periodo"
                value="byPeriod"
              />
            </RadioGroup>
          </FormControl>
          {value === 'receipt' && (
            <Button
              color="primary"
              style={{marginTop: '10px'}}
              variant="contained"
            >
              {receiptURL.success ? (
                <Link
                  className={classes.iconTable}
                  download
                  style={{textDecoration: 'none', color: 'white'}}
                  target="_blank"
                  to={{
                    pathname: `${receiptURL.data}`
                  }}
                >
                  {!loading ? 'Descargar Recibo' : 'Cargando...'}
                </Link>
              ):(
                'Recibo aun no Disponible'
              )}
            </Button>
          )} 
          {value === 'byPeriod' && (
            <Box
              alignItems="center"
              display="flex"
              flexDirection="column"
            >
              <Grid
                container
                spacing={2}
                style={{position: 'relative'}}
              >
                <Grid
                  item
                  lg={6}
                  md={6}
                  xs={12}
                >
                  <Typography>Fecha inicial</Typography>
                  <DatePicker
                    dateFormat="yyyy/MM/dd hh:mm aa"
                    endDate={endDate}
                    maxDate={new Date()}
                    onChange={(date) => setStartDate(date)}
                    selected={startDate}
                    showTimeSelect
                    timeIntervals={15}
                    withPortal
                  />
                </Grid>

                <Grid
                  item
                  lg={6}
                  md={6}
                  xs={12}
                >
                  <Typography>Fecha final</Typography>
                  <DatePicker
                    dateFormat="yyyy/MM/dd hh:mm aa"
                    endDate={endDate}
                    maxDate={new Date()}
                    minDate={startDate}
                    onChange={(date) => setEndDate(date)}
                    selected={endDate}
                    showTimeSelect
                    timeIntervals={15}
                    withPortal

                  />
                </Grid>
              </Grid>
              <Button 
                color="primary"
                onClick={handlePeriod}
                style={{marginTop: '10px'}}
                variant="contained"
              >
                Obtener datos
              </Button>
              {byPeriod && showInfo && (
                <Box
                  display="flex"
                  flexDirection="column"
                  gap="10px"
                  style={{marginTop: '10px'}}
                >
                  <Box
                    alignItems="center"
                    display="flex"
                    flexDirection="row"
                    justifyContent="space-between"
                  >
                    <Typography>Consumo (kWh):</Typography>
                    <Typography>{(byPeriod.totalKWh).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}</Typography>
                  </Box>
                  <Box
                    alignItems="center"
                    display="flex"
                    flexDirection="row"
                    justifyContent="space-between"
                  >
                    <Typography>Precio Medio ($/kWh):</Typography>
                    <Typography>{(byPeriod.average).toFixed(2)}</Typography>
                  </Box>
                  <Box
                    alignItems="center"
                    display="flex"
                    flexDirection="row"
                    justifyContent="space-between"
                  >
                    <Typography>Subtotal:</Typography>
                    <Typography>{formattedMoney(byPeriod.subtotal)}</Typography>
                  </Box>
                  <Box
                    alignItems="center"
                    display="flex"
                    flexDirection="row"
                    justifyContent="space-between"
                  >
                    <Typography>IVA:</Typography>
                    <Typography>{formattedMoney(byPeriod.iva)}</Typography>
                  </Box>
                  <Box
                    alignItems="center"
                    display="flex"
                    flexDirection="row"
                    justifyContent="space-between"
                  >
                    <Typography>Total:</Typography>
                    <Typography>{formattedMoney(byPeriod.total)}</Typography>
                  </Box>
                  <Typography
                    style={{marginTop: '10px', textAlign: 'center'}}
                    variant="h6"
                  >¿Deseas recibir el reporte por Whatsapp?</Typography>
                  <TextField
                    fullWidth
                    inputProps={{
                      maxLength: countryCodes[country].maxLength,  // Limitar la cantidad de caracteres permitidos
                    }}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <FormControl>
                            <Select
                              value={country}
                              onChange={handleCountryChange}
                              style={{ marginRight: 8 }}
                            >
                              <MenuItem value="colombia">COL +57</MenuItem>
                              <MenuItem value="mexico">MX +52</MenuItem>
                            </Select>
                          </FormControl>
                        </InputAdornment>
                      ),
                    }}
                    label="Número Telefónico"
                    onChange={handlePhoneChange}
                    value={formatPhone === 0 || formatDate > 5 ? 0 : formatPhone}
                  />
                  <FormControl
                    component="fieldset"
                    style={{marginTop: '10px'}}
                  >
                    <FormLabel
                      component="legend"
                      style={{textAlign: 'center'}}
                    >¿Deseas recibir el reporte por Email?</FormLabel>
                    <RadioGroup
                      aria-label="report"
                      name="report"
                      onChange={(e)=>setSendEmail(e.target.value)}
                      style={{display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', gap: '50px'}}
                      value={sendEmail}
                    >
                      <FormControlLabel
                        control={<Radio />}
                        label="Si"
                        value="yes"
                      />
                      <FormControlLabel
                        control={<Radio />}
                        label="No"
                        value="no"
                      />
                    </RadioGroup>
                  </FormControl>
                  {sendEmail === 'yes' && (
                    <TextField
                      fullWidth
                      label="Email"
                      onChange={e => setEmail(e.target.value)}
                      value={email === 0 ? '' : email}
                    />
                  )}
                  <Button
                    color="primary"
                    disabled={!isButtonEnabled()}
                    onClick={()=>handleSendWhatsapp()}
                    style={{marginTop: '10px'}}
                    variant="contained"
                  >
                    Enviar Ticket Parcial
                  </Button>
                </Box>
              )}
            </Box>
          )} 
        </DialogContent>
        <DialogActions>
          <Button
            color="secondary"
            onClick={handleModal}
            variant="contained"
          >
            Cerrar
          </Button>
        </DialogActions>
      </Dialog>
    </Paper>
  );
}

import React, { useState } from 'react';

import { makeStyles } from '@material-ui/core/styles';
import useForm from 'react-hook-form';
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Button,
  Grid,
} from '@material-ui/core';

import { getStructureReport } from 'js/actions/reportsActions';

import { useDispatch, useSelector } from 'react-redux';
import BlockUi from 'react-block-ui';
import 'react-block-ui/style.css';

 
import Swal from 'sweetalert2';
import { useIntl } from 'react-intl';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap'
  },
  margin: {
    margin: theme.spacing(1)
  },
  withoutLabel: {
    marginTop: theme.spacing(3)
  },
  textField: {
    width: '100%'
  },
  signUpButton: {
    backgroundColor:'#FEC12C',
    width:'100%',
    height:'45px',
    marginTop:'20px'
  }
}));



export default function InputAdornments() {
  const intl = useIntl()
  const classes = useStyles();
  const dispatch = useDispatch();
  const { register, handleSubmit } = useForm();
  const corporatives = useSelector(state => state.corporatives.corporatives);
  
  const [corporative, setCorporative] = useState();
  const [blocking, setBlocking] = useState();

  const onSubmit = () => {  
    if(!corporative){

      Swal.fire({
        title: '',
        text: 'Se requiere seleccionar todos los campos para generar el reporte',
        icon: 'warning'
      });        
      return;
    }
    setBlocking(true);
    dispatch(getStructureReport( endBlocking , corporative));

 
  };

  function endBlocking( ){
    setBlocking(false);
  }

  const handleCorporative = corporative => {
    setCorporative(corporative.target.value);
  };

  return (
    <div>
      <BlockUi
        blocking={blocking}
        tag="div"
      >
        <form
          className={classes.form}
          onSubmit={handleSubmit(onSubmit)}
        >
          <Grid
            container
            spacing={3}
          >
            <Grid
              item
              lg={6}
              md={12}
              xs={12}
            >
              <FormControl
                className={classes.textField}
                variant="outlined"
              >
                <InputLabel id="demo-simple-select-outlined-label">
                  {intl.formatMessage({id: 'app.reports.corpStructure.corpLbl'})}
                </InputLabel>
                <Select
                  id="demo-simple-select-outlined"
                  inputRef={register}
                  labelId="demo-simple-select-outlined-label"
                  name="corporative"
                  onChange={handleCorporative}
                  value={corporative}
                  //labelWidth={labelWidth}
                >
                  {corporatives
                    ? corporatives.map(el => (
                      <MenuItem value={el.id}>{el.name}</MenuItem>
                    ))
                    : <MenuItem disable >Buscando Tarifas</MenuItem> }
                </Select>
              </FormControl>
            </Grid>
            <Grid
              item
              lg={6}
              xs={12}
            >
              <Button
                className={classes.signUpButton}
                color="secondary"
                //onClick={this.handleSubmit}
                size="large"
                type="submit"
                variant="contained"
              >
                {intl.formatMessage({id: 'app.reports.corpStructure.downloadBtn'})}
              </Button>
            </Grid>
          
          </Grid>
        </form>
      </BlockUi>
    </div>
  );
}
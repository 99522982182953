import * as groupActionTypes from '../constants/groupAction-types';

// const executeGetCorporativeSuccess = (state, action) => {
//     return Object.assign({}, state , {
//             corporatives: state.corporatives.concat(action.corporatives)
//         })
// }

function groupReducer(state = { groups: [], loading: false, charge_types: [] }, action) {
  switch (action.type) {
    case groupActionTypes.POST_GROUP_STARTED:
      return {
        ...state,
        loading: true
      };
    case groupActionTypes.POST_GROUP_SUCCESS:
      return {
        ...state,
        groups: state.groups.push(action.payload),
        loading: false
      };
    case groupActionTypes.POST_GROUP_FAILURE:
      return {
        ...state,
        error: action.payload.error,
        loading: false
      };
    case groupActionTypes.GET_GROUP_STARTED:
      return { ...state, loading: true}
    case groupActionTypes.GET_GROUP_SUCCESS:
      return { ...state, groups: action.groups, loading: false };
    case groupActionTypes.GET_GROUP_FAILURE:
      return { ...state, error: action.error, loading: false}
    case groupActionTypes.GET_GROUP_LIST_SUCCESS:
      return { ...state,  groupsList: action.groups } ;      

    case groupActionTypes.DELETE_GROUP_SUCCESS:
      return {
        ...state,
        groups: state.groups.filter(({ id }) => id !== action.payload.id)
      };
    case groupActionTypes.GET_CHARGE_TYPE_LIST:
      return {
        ...state,
        charge_types: action.charge_types
      };
    case groupActionTypes.PUT_GROUP_STARTED:
      return {
        ...state,
        loading: true
      };
    case groupActionTypes.IMPORT_FILE_STARTED:
      return {
        ...state,
        loading: true
      };
    case groupActionTypes.IMPORT_FILE_SUCCESS:
      return {
        ...state,
        loading: false
      };
    case groupActionTypes.IMPORT_FILE_FAILURE:
      return {
        ...state,
        loading: false
      }

    default:
      return state;
  }
}

export default groupReducer;

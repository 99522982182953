import React, { useState, useEffect } from 'react';
import styles from './styles';

// Externals
import { useSelector } from 'react-redux';

// Material UI
import {
  Paper,
  Grid,
  Chip,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  CircularProgress,
  makeStyles
} from '@material-ui/core';

// Utils
import moment from 'moment';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { useIntl } from 'react-intl';

const useStyles = makeStyles(styles);

export default function Temperature(props) {
  const { weather, updateMonth, updateYear } = props;
  const intl = useIntl();
  const classes = useStyles();
  const [days, setDays] = useState([]);
  const [weatherData, setWeatherData] = useState();

  const year = updateYear ? updateYear : new Date().getFullYear();
  const month = updateMonth ? updateMonth : new Date().getMonth() + 1;
  const daysInMonth = updateYear ? moment(`${updateYear}${updateMonth}`).daysInMonth() : moment().daysInMonth();
  const localeNav = useSelector(state => state.prefs.locale);

  useEffect(() => {
    const daysInMonth = updateYear ? moment(`${updateYear}${updateMonth}`).daysInMonth() : moment().daysInMonth();
    const currentMonth = moment().month();
    const currentYear = moment().year();
    const daysArray = [];
    moment.locale(localeNav);
    for (let i = 1; i <= daysInMonth; i++) {
      const date = moment({ year: updateYear ? updateYear : currentYear, month: updateMonth  ? updateMonth - 1: currentMonth, day: i });
      daysArray.push({
        day: i,
        weekday: date.format('ddd')
      });
    }

    setDays(daysArray);
  }, [localeNav, updateMonth, updateYear]);

  useEffect(() => {
    // Llenar weatherData con los datos recibidos de la API
    const newWeatherData = {}; // <-- Crea un nuevo objeto aquí
    for (const dateKey in weather) {
      newWeatherData[dateKey] = weather[dateKey];
    }

    // Rellenar los días restantes con valores predeterminados
    for (let day = 1; day <= daysInMonth; day++) {
      const dateKey = `${year}${String(month).padStart(2, '0')}${String(
        day
      ).padStart(2, '0')}`;
      if (!(dateKey in newWeatherData)) {
        newWeatherData[dateKey] = {
          temp: 0,
          temp_min: 0,
          temp_max: 0,
          temp_color: 'white',
          temp_max_color: 'white',
          temp_min_color: 'white',
        };
      }
    }

    setWeatherData(newWeatherData);
  }, [weather,updateYear, updateMonth]);

  return (
    <Paper className={classes.paper}>
      <Grid
        container
        lg={12}
        md={12}
        style={{
          width: '100%',
          justifyContent: 'space-between',
          marginBottom: '1rem',
          alignItems: 'center'
        }}
        xl={12}
        xs={12}
      >
        <Chip
          className={classes.title}
          label={intl.formatMessage({id: 'app.temp.title'})}
        />
      </Grid>
      {weatherData ? (
        <PerfectScrollbar>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>{intl.formatMessage({id:'app.charge.board.totPerDay.label'})} C°</TableCell>
                {days.map((day, index) => (
                  <TableCell key={index}>
                    {day.day}
                    <br />
                    {day.weekday.charAt(0).toUpperCase() +
                      day.weekday.slice(1, 3)}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow style={{height: '0px'}}>
                <TableCell>{intl.formatMessage({id: 'app.tempProm.title'})}</TableCell>
                {Object.values(weatherData).map(data => (
                  <TableCell
                    component="th"
                    scope="row"
                    style={{
                      backgroundColor: data.temp_color,
                      fontWeight: 'bold',
                    }}
                  >
                    {data.temp}°
                  </TableCell>
                ))}
              </TableRow>
              <TableRow style={{height: '0px'}}>
                <TableCell>{intl.formatMessage({id: 'app.tempMax.title'})}</TableCell>
                {Object.values(weatherData).map(data => (
                  <TableCell
                    component="th"
                    scope="row"
                    style={{
                      backgroundColor: data.temp_max_color,
                      fontWeight: 'bold',
                      borderTop: '4px solid white'
                    }}
                  >
                    {data.temp_max}°
                  </TableCell>
                ))}
              </TableRow>
              <TableRow style={{height: '0px'}}>
                <TableCell>{intl.formatMessage({id: 'app.tempMin.title'})}</TableCell>
                {Object.values(weatherData).map(data => (
                  <TableCell
                    component="th"
                    scope="row"
                    style={{
                      backgroundColor: data.temp_min_color,
                      fontWeight: 'bold',
                      borderTop: '4px solid white'
                    }}
                  >
                    {data.temp_min}°
                  </TableCell>
                ))}
              </TableRow>
            </TableBody>
          </Table>
        </PerfectScrollbar>
      ) : (
        <CircularProgress />
      )}
    </Paper>
  );
}

/* eslint-disable react/no-multi-comp */
import React from 'react';
import PropTypes from 'prop-types';
import { AppBar, Box, Tab, Tabs, Typography, withStyles } from '@material-ui/core';
import { Dashboard as DashboardLayout } from 'layouts';
import { ControlRateForm, CorpStructure, TotalReportForm } from './components';
import styles from './style';
import { isAllowed } from 'helpers';
import { useSelector } from 'react-redux';
import { useIntl } from 'react-intl';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      aria-labelledby={`scrollable-auto-tab-${index}`}
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      role="tabpanel"
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `scrollable-auto-tab-${index}`,
    'aria-controls': `scrollable-auto-tabpanel-${index}`,
  };
}

const UserList = () => {
  const intl = useIntl()
  const user = useSelector(state => state.user.user);
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <DashboardLayout title="Reportes">
      <AppBar
        color="transparent"
        position="static"
        style={{boxShadow: 'none', marginTop: '.5rem'}}
      >
        <Tabs
          centered
          indicatorColor="primary"
          onChange={handleChange}
          TabIndicatorProps={{
            style: {
              backgroundColor: '#A53693'
            }
          }}
          value={value}
          variant="fullWidth"
        >
          <Tab
            label={intl.formatMessage({id: 'app.reports.hourReports.title'})}
            {...a11yProps(0)}
          />
          <Tab
            label={intl.formatMessage({id: 'app.reports.totReports.title'})}
            {...a11yProps(1)}
          />
          {isAllowed(user.permissions, ['download_report_structure']) &&
            <Tab
              label={intl.formatMessage({id: 'app.reports.corpStructure.title'})}
              {...a11yProps(2)}
            />
          }
        </Tabs>
      </AppBar>
      <TabPanel
        index={0}
        value={value}
      >
        <ControlRateForm />
      </TabPanel>
      <TabPanel
        index={1}
        value={value}
      >
        <TotalReportForm />
      </TabPanel>
      {isAllowed(user.permissions, ['download_report_structure']) &&
      <TabPanel
        index={2}
        value={value}
      >
        <CorpStructure />
      </TabPanel>}
    </DashboardLayout>
  );
};

UserList.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(UserList);
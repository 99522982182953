export default theme => ({
  root: {
    padding: theme.spacing.unit * 3

  },
  content: {
    marginTop: theme.spacing.unit * 5,
  },
  progressWrapper: {
    paddingTop: '48px',
    paddingBottom: '24px',
    display: 'flex',
    justifyContent: 'center'
  },
  table:{
    width: '90%',
    margin: '0 auto'
  },
  chip: {
    fontWeight: 700,
    backgroundColor: '#730065',
    fontSize: '16px',
    color: 'white'
  },
  pagination: {
    marginTop: '24px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end'
  },
  option: {
    margin: '0 auto',

  },
});
